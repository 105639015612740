import React, { createContext, useState } from "react";
import DialogBase from "../../components/dialog/DialogBase";
import { Button } from "@mui/material";

export const TipContext = createContext();

function TipProvider({ children }) {
  const [config, setConfig] = useState({
    open: false,
    title: "",
    content: "",
  });

  const showTip = ({ title, content }) => {
    setConfig({ ...config, open: true, title, content });
  };
  const handleCloseDialog = () => {
    setConfig({ ...config, open: false });
  };

  return (
    <>
      <DialogBase
        width="90vw"
        open={config.open}
        onClose={handleCloseDialog}
        title={config.title}
        content={config.content}
        actions={[
          <Button
            key="1"
            variant="contained"
            sx={{ color: "common.white", textTransform: "none" }}
            onClick={handleCloseDialog}
          >
            Đã hiểu
          </Button>,
        ]}
      />
      <TipContext.Provider value={showTip}>{children}</TipContext.Provider>
    </>
  );
}

export default TipProvider;
