import { updateProductGroup } from "../reducers/product-group.reducer";

export const getProductGroups = async ({
  asyncSearchList,
  showAlert,
  dispatch,
}) => {
  try {
    const data = await asyncSearchList({
      apiCode: "dmnvt",
      condition: { page: 1, limit: 10000, q: { status: true } },
    });
    dispatch(updateProductGroup(data));
  } catch (error) {
    showAlert({
      type: "error",
      message: error?.message || "Something went wrong!",
    });
  }
};
