import { createSlice } from "@reduxjs/toolkit";

const usergroupSlice = createSlice({
  name: "usergroup",
  initialState: {
    data: [],
  },
  reducers: {
    updateUsergroup(state, action) {
      state.data = action.payload;
    },
  },
});

export const { updateUsergroup } = usergroupSlice.actions;
export default usergroupSlice.reducer;
