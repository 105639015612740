import React, { useState } from "react";
import Menu from "../menu/Menu";
import AppBar from "../../appbar/AppBar";
import { Fab, IconButton, Stack, Typography } from "@mui/material";
import { IoMenu } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

function MainLayout({ title, slotComponent, showCreateHd = true, children }) {
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Menu open={openMenu} onClose={() => setOpenMenu(false)} />
      <AppBar>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ height: "100%" }}
          spacing="10px"
        >
          <IconButton
            onClick={() => setOpenMenu(true)}
            sx={{
              width: "45px",
              height: "45px",
              padding: "4px",
              color: "common.white",
            }}
          >
            <IoMenu size={25} />
          </IconButton>
          {title && (
            <Typography variant="h1" sx={{ color: "common.white" }}>
              {title}
            </Typography>
          )}
          {slotComponent}
        </Stack>
      </AppBar>
      {showCreateHd && (
        <Fab
          color="primary"
          onClick={() => navigate("/order")}
          sx={{
            position: "fixed",
            right: "10px",
            bottom: "10px",
            boxShadow: "none",
            borderRadius: "20px",
            textWrap: "nowrap",
            width: "auto",
            py: 0,
            px: 2,
            fontSize: "16px",
            color: "common.white",
            "&:active": {
              boxShadow: "none",
            },
          }}
        >
          Tạo đơn
        </Fab>
      )}
      {children}
    </>
  );
}

export default MainLayout;
