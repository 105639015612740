import { useContext } from "react";
import { ConfirmContext } from "../../contexts/confirm-context/ConfirmProvider";

function useConfirmContext() {
  const value = useContext(ConfirmContext);
  if (!value)
    throw new Error("ConfirmContext must be use inside ConfirmProvider");
  return value;
}

export default useConfirmContext;
