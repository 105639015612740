import React, { useEffect, useState } from "react";
import { IconButton, Stack, TextField } from "@mui/material";
import { FaMinus, FaPlus } from "react-icons/fa";

function Quantity({
  defaultValue,
  onChange,
  readOnly,
  minValue = 1,
  disabledMinus,
  warning = false,
}) {
  const [quantity, setQuantity] = useState(defaultValue || 0);

  const handleNumberChange = (num) => {
    let val = num;
    if (isNaN(val) || val === "") {
      val = 1;
      setQuantity(1);
    }
    if (Number(val) < minValue) {
      val = minValue;
    }
    onChange(+val);
  };

  useEffect(() => {
    setQuantity(defaultValue);
  }, [defaultValue]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      onClick={(e) => e.stopPropagation()}
      sx={{ pointerEvents: readOnly ? "none" : "all" }}
    >
      <IconButton
        disabled={disabledMinus}
        onClick={() => handleNumberChange(quantity - 1)}
        sx={{
          width: "42px",
          height: "42px",
          color: "primary.main",
        }}
      >
        <FaMinus size={16} strokeWidth={1} />
      </IconButton>
      <TextField
        autoComplete="off"
        value={quantity}
        onChange={(e) => setQuantity(e.target.value)}
        onBlur={() => handleNumberChange(quantity)}
        sx={{
          width: "60px",
          "& .MuiInputBase-root": {
            borderRadius: "0px",
            borderBottom: "1px solid",
            borderColor: "divider",
            "& .MuiInputBase-input": {
              padding: 0,
              textAlign: "center",
              color: warning ? "error.main" : "",
            },
            "& fieldset": {
              border: "none",
            },
          },
        }}
      />
      <IconButton
        onClick={() => handleNumberChange(quantity + 1)}
        sx={{
          width: "42px",
          height: "42px",
          color: "primary.main",
        }}
      >
        <FaPlus size={16} />
      </IconButton>
    </Stack>
  );
}

export default Quantity;
