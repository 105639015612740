import { useContext } from "react";
import { TipContext } from "../../contexts/tip-context/TipProvider";

const useTipContext = () => {
  const value = useContext(TipContext);
  if (!value) throw new Error("TipContext must be used inside TipProvider");
  return value;
};

export default useTipContext;
