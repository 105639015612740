import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { privateRoutes, restrictedRoutes } from ".";

function RenderRoutes() {
  return (
    <Routes>
      {restrictedRoutes.map((route) => (
        <Route key={route.id} path={route.path} element={route.element} />
      ))}
      {privateRoutes.map((route) => (
        <Route key={route.id} path={route.path} element={route.element} />
      ))}
      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>
  );
}

export default RenderRoutes;
