import React, { createContext, useContext, useEffect, useState } from "react";
import AppBar from "../../components/appbar/AppBar";
import { Button, Divider, IconButton, Stack, Typography } from "@mui/material";
import { MdClose } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { LuInfo } from "react-icons/lu";
import DialogBase from "../../components/dialog/DialogBase";
import OrderInfo from "./components/order-info/OrderInfo";
import Customer from "./components/customer/Customer";
import ForceCustomer from "./components/force-customer/ForceCustomer";
import { useDispatch, useSelector } from "react-redux";
import {
  resetOrder,
  updateCurrentHd,
  updateCustomer,
  updateTempHds,
} from "../../redux/reducers/order.reducer";
import SearchProduct from "./components/search-product/SearchProduct";
import useApisContext from "../../hooks/hookContext/useApisContext";
import { v4 } from "uuid";
import useAlertContext from "../../hooks/hookContext/useAlertContext";
import { cloneDeep } from "lodash";
import moment from "moment";
import Details from "./components/details/Details";
import Bottom from "./components/bottom/Bottom";
import useBackdropContext from "../../hooks/hookContext/useBackdropContext";

const OrderContext = createContext();

function OrderPage() {
  const showAlert = useAlertContext();
  const { asyncSearchList, asyncPutData, asyncPostData } = useApisContext();
  const { customer, currentHd, tempHds } = useSelector((state) => state.order);
  const { currentStore } = useSelector((state) => state.store);
  const { user } = useSelector((state) => state.auth);
  const [, setBackdrop] = useBackdropContext();
  const [openConfirmBack, setOpenConfirmBack] = useState(false);
  const [openOrderInfo, setOpenOrderInfo] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const handleCloseConfirmBack = () => {
    window.history.pushState(null, null, window.location.href);
    setOpenConfirmBack(false);
  };

  const saveHd = async () => {
    try {
      setBackdrop(true);
      const { _id, details, trang_thai, ...fields } = currentHd;
      const method = !!trang_thai ? asyncPutData : asyncPostData;
      const hdToSave = {
        ...fields,
        trang_thai: 0,
        ten_trang_thai: "Đặt hàng",
        details: details.map((detail) => {
          const { _id, ...detailData } = detail;
          return {
            ...detailData,
            gia_ban_nt: detailData.gia_ban,
            tien_nt: detailData.tien,
            tien_ck_nt: detailData.tien_ck,
            tien_thue_nt: detailData.tien_thue,
            tien_xuat: detailData.tien - detailData.tien_ck,
            tien_xuat_nt: detailData.tien - detailData.tien_ck,
          };
        }),
      };
      // if (user?.admin || allowAuthorize({ type: "update", module: "hd2" })) {
      //   hdToSave.trang_thai = "5";
      //   hdToSave.ten_trang_thai = "Hoàn tất";
      // }
      const resp = await method({
        apiCode: "hd2",
        data: hdToSave,
        uniqueValue: _id,
      });
      if (resp?.error) {
        showAlert({
          type: "error",
          message: resp?.error || "Something went wrong!",
        });
        return;
      }
      if (currentHd.payed) {
        await createPhieuThu(currentHd);
      }
      await createPhieuThu(resp);
      dispatch(resetOrder());
      navigate("/dashboard");
      showAlert({ type: "success", message: "Đặt hàng thành công" });
    } catch (error) {
      showAlert({
        type: "error",
        message: error?.message || "Something went wrong!",
      });
    } finally {
      setBackdrop(false);
    }
  };

  const createPhieuThu = async (hd) => {
    try {
      const {
        ma_kho,
        ten_kho,
        ma_kh,
        ngay_ct,
        exfields: { payed },
      } = hd;
      const dataPost = {
        trang_thai: 0,
        ma_kho: ma_kho || "",
        ten_kho: ten_kho || "",
        ma_kh: ma_kh || "",
        ngay_ct,
        tk_no: "1131",
        dien_giai: "Phiếu thu được tạo tự động khi đặt hàng",
        details: [
          {
            ma_kh: ma_kh || "",
            tien_nt: payed || 0,
            tk_co: "1131",
          },
        ],
      };
      await asyncPostData({ apiCode: "pt1", data: dataPost });
    } catch (error) {
      showAlert({
        type: "error",
        message: error?.message || "Something went wrong!",
      });
    }
  };

  const createHd = async () => {
    try {
      const respNv = await asyncSearchList({
        apiCode: "dmnv",
        condition: { page: 1, limit: 1, q: { user: user?.email } },
      });
      const hd = {
        _id: v4(),
        tk_no: 1131,
        ma_kho: currentStore?.ma_kho || "",
        ten_kho: currentStore?.ten_kho || "",
        ma_nv: respNv?.[0]?.ma_nv || "",
        ten_nv: respNv?.[0]?.ten_nv || "",
        ma_kh: customer?.ma_kh || "",
        ten_kh: customer?.ten_kh || "",
        ngay_ct: moment().toISOString(),
        exfields: {},
        details: [],
      };
      dispatch(updateCurrentHd(hd));
      return hd;
    } catch (error) {
      showAlert({
        type: "error",
        message: error?.message || "Something went wrong!",
      });
    }
  };

  const handleCancel = () => {
    dispatch(resetOrder());
    navigate(-1);
  };

  const handleSaveTemp = () => {
    dispatch(updateTempHds([...tempHds, currentHd]));
    dispatch(resetOrder());
    navigate(-1);
  };

  const handleUpdateCurrentHd = (updateData) => {
    let currentHdClone = cloneDeep(currentHd);
    currentHdClone = { ...currentHdClone, ...updateData };
    dispatch(updateCurrentHd(currentHdClone));
  };

  useEffect(() => {
    if (customer && !currentHd && !location?.state) {
      createHd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, currentHd]);

  useEffect(() => {
    if (location?.state) {
      dispatch(updateCurrentHd(location.state));
      dispatch(
        updateCustomer({
          ma_kh: location?.state?.ma_kh,
          ten_kh: location?.state?.ten_kh,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", () => {
      setOpenConfirmBack(true);
    });
    return () => {
      dispatch(resetOrder());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OrderContext.Provider
      value={{
        saveHd,
        handleUpdateCurrentHd,
      }}
    >
      <ForceCustomer />
      <OrderInfo open={openOrderInfo} onClose={() => setOpenOrderInfo(false)} />
      <DialogBase
        open={openConfirmBack}
        onClose={handleCloseConfirmBack}
        title="Thoát khỏi trang"
        content="Bạn có muốn lưu đơn tạm không ?"
        width="600px"
        actions={[
          <Button key={1} onClick={handleCancel} sx={{ color: "error.main" }}>
            Hủy đơn
          </Button>,
          <Button key={2} onClick={handleSaveTemp}>
            Lưu
          </Button>,
        ]}
      />
      <AppBar>
        <Stack
          sx={{ height: "100%" }}
          direction="row"
          alignItems="center"
          spacing="10px"
        >
          <IconButton
            onClick={() => {
              navigate(-1);
              setOpenConfirmBack(true);
            }}
            sx={{ color: "common.white", padding: 0 }}
          >
            <MdClose size={25} />
          </IconButton>
          <Typography variant="h1" sx={{ color: "common.white", flexGrow: 1 }}>
            Hóa đơn
          </Typography>
          <IconButton
            onClick={() => setOpenOrderInfo(true)}
            sx={{ color: "common.white" }}
          >
            <LuInfo size={25} />
          </IconButton>
        </Stack>
      </AppBar>
      <SearchProduct />
      <Divider sx={{ borderBottomWidth: "1px" }} />
      <Customer />
      <Details />
      <Bottom />
    </OrderContext.Provider>
  );
}

export default OrderPage;

export const useOrderContext = () => {
  const value = useContext(OrderContext);
  if (!value) {
    throw new Error("OrderContext must be used inside OrderProvider");
  }
  return value;
};
