import React from "react";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { deDE } from "@mui/x-date-pickers/locales";

const theme = createTheme({
  palette: {
    primary: {
      main: "#65B741",
      100: "#e0fbd4",
      200: "#b9f59f",
      300: "#a4f482",
    },
    secondary: {
      main: "#4B70F5",
    },
    info: {
      main: "#3DC2EC",
    },
    warning: {
      main: "#FFF455",
    },
    error: {
      main: "#FF0000",
    },
    success: {
      main: "#06D001",
    },
    background: { default: "#EEEEEE", paper: "#fdfdfd" },
    divider: "#dddddd",
    text: { primary: "#000000", secondary: "#888888", disabled: "#adadad" },
    grey: { A100: "#f3f3f3" },
    mode: "light",
    common: { black: "#000", white: "#fff" },
    action: {},
  },
  deDE,
  zIndex: {
    fab: 99,
    drawer: 100,
    modal: 101,
    tooltip: 102,
    snackbar: 103,
    speedDial: 104,
  },
  shape: { borderRadius: 4 },
  typography: {
    h1: { fontSize: "20px", fontWeight: 500 },
    h2: { fontSize: "19px" },
    h3: { fontSize: "18px" },
    h4: { fontSize: "17px" },
    h5: { fontSize: "16px" },
    h6: { fontSize: "15px" },
    subtitle1: { fontSize: "16px" },
    subtitle2: { fontSize: "15px" },
    body1: { fontSize: "16px" },
    body2: { fontSize: "15px" },
    fontFamily: `"Inter", "sans-serif"`,
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  components: {
    MuiContainer: {
      defaultProps: { maxWidth: "md" },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          fontSize: "16px",
          "&:hover": { boxShadow: "none" },
        },
      },
    },
  },
});

function ThemeProvider({ children }) {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}

export default ThemeProvider;
