import React, { useMemo, useState } from "react";
import {
  Box,
  Container,
  Divider,
  Drawer,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AppBar from "../../../../components/appbar/AppBar";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import { useSelector } from "react-redux";
import FilterRadios from "../../../../components/filter/FilterRadios";

function DrawerFilter({ open, onClose, filter, setFilter }) {
  const productGroups = useSelector((state) => state.productGroup.data);
  const [searchText, setSearchText] = useState("");

  const handleClose = () => {
    setSearchText("");
    onClose();
  };

  const groups = useMemo(() => {
    const result = [];
    productGroups.forEach((item) => {
      const normalizedSearchText = searchText
        .trim()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
      const normalizedItemName = item.ten_nvt
        .trim()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();

      if (
        normalizedItemName.includes(normalizedSearchText) ||
        item.ten_nvt.includes(searchText)
      ) {
        result.push({ label: item.ten_nvt, value: item._id });
      }
    });
    return result;
  }, [productGroups, searchText]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { backgroundColor: "transparent", width: "100vw" } }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          backgroundColor: "background.default",
        }}
      >
        <AppBar>
          <Stack
            sx={{ width: "100%", height: "100%" }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing="10px" alignItems="center">
              <IconButton
                sx={{ color: "common.white", padding: 0 }}
                onClick={handleClose}
              >
                <MdOutlineArrowBackIosNew size={25} />
              </IconButton>
              <Typography variant="h1" sx={{ color: "common.white" }}>
                Nhóm hàng
              </Typography>
            </Stack>
          </Stack>
        </AppBar>
        <Box sx={{ backgroundColor: "background.paper" }}>
          <Container sx={{ height: "50px" }}>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Tìm nhóm hàng"
              autoComplete="off"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              sx={{
                "& .MuiInputBase-root": {
                  "& .MuiInputBase-input": {
                    padding: 0,
                    height: "50px",
                  },
                  "& fieldset": {
                    border: "none",
                  },
                },
              }}
              InputProps={{
                endAdornment: <CiSearch size={25} />,
              }}
            />
          </Container>
        </Box>
        <Divider sx={{ margin: 0, borderBottomWidth: "1px" }} />
        <Container sx={{ backgroundColor: "background.paper" }}>
          <Box
            sx={{
              height: "calc(100vh - 60px - 50px - 1px)",
              overflow: "auto",
              paddingLeft: "5px",
            }}
          >
            <FilterRadios
              defaultValue={filter?.group?.value || ""}
              values={[{ label: "Tất cả", value: "" }, ...groups]}
              onChange={(val) => {
                const group = groups.find((g) => g.value === val);
                setFilter((prev) => ({ ...prev, group }));
              }}
            />
          </Box>
        </Container>
      </Box>
    </Drawer>
  );
}

export default DrawerFilter;
