import {
  Box,
  Container,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import AppBar from "../../../components/appbar/AppBar";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import TextInput from "../../../components/input/TextInput";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { axiosPublic } from "../../../utils/httpRequest";
import useToken from "../../../hooks/useToken";
import useAlertContext from "../../../hooks/hookContext/useAlertContext";
import { LoadingButton } from "@mui/lab";

const schema = yup.object({
  //   oldPassword: yup.string().required("Vui lòng nhập mật khẩu cũ"),
  //   newPassword: yup
  //     .string()
  //     .required("Vui lòng nhập mật khẩu mới")
  //     .matches(
  //       /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
  //       "Mật khẩu phải có từ 6 ký tự, ít nhất 1 chữ cái thường và 1 chữ cái in hoa"
  //     ),
  //   reNewPassword: yup
  //     .string()
  //     .required("Vui lòng xác nhận lại mật khẩu")
  //     .equals([yup.ref("newPassword")], "Mật khẩu xác nhận không trùng khớp"),
});

function DrawerChangePassword({ open, onClose }) {
  const token = useToken();
  const showAlert = useAlertContext();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const handleClear = () => {
    reset({ oldPassword: "", newPassword: "", reNewPassword: "" });
  };

  const updatePassword = async (dataPost) => {
    try {
      const resp = await axiosPublic.post(
        `/api/changepassword?access_token=${token}`,
        dataPost
      );
      if (resp && resp?.status === 200) {
        showAlert({ type: "success", message: "Đổi mật khẩu thành công" });
        handleClear();
      } else {
        showAlert({
          type: "error",
          message: resp?.response?.data?.message || "Something went wrong!",
        });
      }
    } catch (error) {
      showAlert({
        type: "error",
        message: error?.response?.data?.error || "Something went wrong!",
      });
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      PaperProps={{ sx: { width: "100vw", height: "100vh" } }}
    >
      <AppBar>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ height: "100%" }}
        >
          <Stack
            sx={{ width: "100%", height: "100%" }}
            direction="row"
            alignItems="center"
            spacing={2}
          >
            <IconButton sx={{ color: "common.white" }} onClick={onClose}>
              <MdOutlineArrowBackIosNew size={25} />
            </IconButton>
            <Typography variant="h1" sx={{ color: "common.white" }}>
              Thay đổi mật khẩu
            </Typography>
          </Stack>
          <LoadingButton
            loading={isSubmitting}
            variant="text"
            sx={{ color: "common.white" }}
            onClick={handleSubmit(updatePassword)}
          >
            Lưu
          </LoadingButton>
        </Stack>
      </AppBar>
      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 60px)",
          overflow: "auto",
          backgroundColor: "background.default",
        }}
      >
        <Stack
          sx={{ width: "100%", backgroundColor: "background.paper", py: 2 }}
          spacing={2}
        >
          <Container>
            <TextInput
              type="password"
              label="Mật khẩu cũ"
              placeholder="Nhập mật khẩu cũ"
              InputProps={{ autoComplete: "off" }}
              name="oldPassword"
              register={register}
              errorMessage={errors?.oldPassword?.message}
            />
          </Container>
          <Container>
            <TextInput
              type="password"
              label="Mật khẩu mới"
              placeholder="Nhập mật khẩu mới"
              InputProps={{ autoComplete: "off" }}
              name="newPassword"
              register={register}
              errorMessage={errors?.newPassword?.message}
            />
          </Container>
          <Container>
            <TextInput
              type="password"
              label="Xác nhận lại"
              placeholder="Nhập lại mật khẩu mới"
              InputProps={{ autoComplete: "off" }}
              name="reNewPassword"
              register={register}
              errorMessage={errors?.reNewPassword?.message}
            />
          </Container>
        </Stack>
      </Box>
    </Drawer>
  );
}

export default DrawerChangePassword;
