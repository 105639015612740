import React from "react";
import { List, ListItemButton, ListItemText } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentStore } from "../../../redux/reducers/store.reducer";

function Stores({ topHeight }) {
  const { data: stores, currentStore } = useSelector((state) => state.store);
  const dispatch = useDispatch();

  const handleUpdateStore = (store) => {
    dispatch(updateCurrentStore(store));
  };

  return (
    <List sx={{ height: `calc(100vh - ${topHeight})`, overflow: "auto" }}>
      {stores?.length > 0 &&
        stores.map((store) => {
          const isActive = store._id === currentStore?._id;
          return (
            <ListItemButton
              key={store._id}
              sx={{
                backgroundColor: isActive ? "primary.100" : "",
                "&:hover": {
                  backgroundColor: isActive ? "primary.100" : "",
                },
              }}
              onClick={() => handleUpdateStore(store)}
            >
              <ListItemText>{store.ten_kho}</ListItemText>
            </ListItemButton>
          );
        })}
    </List>
  );
}

export default Stores;
