import { useContext } from "react";
import { BackdropContext } from "../../contexts/backdropContext/BackdropProvider";

const useBackdropContext = () => {
  const value = useContext(BackdropContext);
  if (!value) {
    throw new Error("BackdropContext must be used inside BackdropProvider");
  }
  return value;
};
export default useBackdropContext;
