import React, { createContext } from "react";
import { ID_APP } from "../../utils/constants";
import { generateSearchKeywords } from "../../utils/helpers";
import { axiosPublic } from "../../utils/httpRequest";
import useAlertContext from "../../hooks/hookContext/useAlertContext";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export const ApisContext = createContext();

function ApisProvider({ children }) {
  const showAlert = useAlertContext();
  const axiosPrivate = useAxiosPrivate();

  // async search list
  const asyncSearchList = async ({
    apiCode,
    condition = {},
    withIdApp = true,
  }) => {
    try {
      if (!navigator.onLine) {
        return;
      }
      const resp = await axiosPrivate.post(
        `/search${withIdApp ? `/${ID_APP}` : ""}/${apiCode}`,
        condition
      );
      if (resp && resp.status === 200) {
        return resp.data;
      } else {
        showAlert({
          type: "error",
          message: resp?.response?.data?.error || "Server error",
        });
        return resp.response.data;
      }
    } catch (error) {
      showAlert({
        type: "error",
        message: error?.response?.data?.error || "Server error",
      });
    }
  };
  // async get list
  const asyncGetList = async ({
    apiCode,
    condition = {},
    withIdApp = true,
  }) => {
    try {
      if (!navigator.onLine) {
        return;
      }
      const resp = await axiosPrivate.get(
        `${withIdApp ? `/${ID_APP}` : ""}/${apiCode}${generateSearchKeywords(
          condition
        )}`
      );
      if (resp && resp.status === 200) {
        return resp.data;
      } else {
        showAlert({
          type: "error",
          message: resp?.response?.data?.error || "Server error",
        });
        return resp.response.data;
      }
    } catch (error) {
      showAlert({
        type: "error",
        message: error?.response?.data?.error || "Server error",
      });
    }
  };
  // async get
  const asyncGet = async ({
    apiCode,
    uniqueValue,
    withIdApp = true,
    condition,
  }) => {
    try {
      if (!navigator.onLine) {
        return;
      }
      const resp = await axiosPrivate.get(
        `${
          withIdApp ? `/${ID_APP}` : ""
        }/${apiCode}/${uniqueValue}${generateSearchKeywords(condition)}`
      );
      if (resp && resp.status === 200) {
        return resp.data;
      } else {
        showAlert({
          type: "error",
          message: resp?.response?.data?.error || "Something went wrong!",
        });
        return resp.response.data;
      }
    } catch (error) {
      showAlert({
        type: "error",
        message: error?.message || "Something went wrong!",
      });
    }
  };
  // async get list deleted
  const asyncGetListDeleted = async (
    ma_danh_muc,
    condition = {},
    version = "v1"
  ) => {
    try {
      if (!navigator.onLine) {
        return;
      }
      const resp = await axiosPrivate.post(
        `/${version}/danhmuc/${ma_danh_muc}/search/deleted`,
        condition
      );
      if (resp && resp.status === 200) {
        return resp.data;
      } else {
        showAlert({
          type: "error",
          message: resp?.response?.data?.error || "Server error",
        });
        return resp.response.data;
      }
    } catch (error) {
      showAlert({
        type: "error",
        message: error?.response?.data?.error || "Server error",
      });
    }
  };
  // async post data
  const asyncPostData = async ({
    method = "post",
    apiCode,
    data,
    endpoint,
    options,
  }) => {
    try {
      if (!navigator.onLine) {
        return;
      }
      const resp = await axiosPrivate[method](
        `/${ID_APP}/${apiCode}${endpoint || ""}`,
        data,
        options
      );
      if (resp && resp.status === 200) {
        return resp.data;
      } else {
        showAlert({
          type: "error",
          message: resp?.response?.data?.error || "Server error",
        });
        return resp.response.data;
      }
    } catch (error) {
      showAlert({
        type: "error",
        message: error?.response?.data?.error || "Server error",
      });
    }
  };
  // async put data
  const asyncPutData = async ({ apiCode, uniqueValue, data }) => {
    try {
      if (!navigator.onLine) {
        return;
      }
      const resp = await axiosPrivate.put(
        `/${ID_APP}/${apiCode}/${uniqueValue}`,
        data
      );
      if (resp && resp.status === 200) {
        return resp.data;
      } else {
        showAlert({
          type: "error",
          message: resp?.response?.data?.error || "Server error",
        });
        return resp.response.data;
      }
    } catch (error) {
      showAlert({
        type: "error",
        message: error?.response?.data?.error || "Server error",
      });
    }
  };
  // async delete
  const asyncDelete = async ({ apiCode, uniqueValue }) => {
    try {
      if (!navigator.onLine) {
        return;
      }
      const resp = await axiosPrivate.delete(
        `/${ID_APP}/${apiCode}/${uniqueValue}`
      );
      if (resp && resp.status === 200) {
        return resp.data;
      } else {
        showAlert({
          type: "error",
          message: resp?.response?.data?.error || "Server error",
        });
        return resp.response.data;
      }
    } catch (error) {
      showAlert({
        type: "error",
        message: error?.response?.data?.error || "Server error",
      });
    }
  };
  const asyncGetReport = async ({ apiCode, queryObject = {}, data = {} }) => {
    try {
      if (!navigator.onLine) {
        return;
      }
      if (!apiCode) return;
      let queryString = "";
      for (let key in queryObject) {
        queryString += `&${key}=${queryObject[key]}`;
      }
      const resp = await axiosPrivate.get(
        `/${ID_APP}/${apiCode}?rpt=1${queryString}`,
        data
      );
      if (resp && resp.status === 200) {
        return resp.data;
      } else {
        showAlert({
          type: "error",
          message: resp?.response?.data?.error || "Server error",
        });
        return resp.response.data;
      }
    } catch (error) {
      showAlert({
        type: "error",
        message: error?.response?.data?.error || "Server error",
      });
    }
  };
  const uploadFile = async ({ formData, folder, token }) => {
    try {
      if (!navigator.onLine) {
        return;
      }
      const resp = await axiosPublic.post(
        `/api/uploadfile?json=1&folder=${folder}&access_token=${token}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      if (resp && resp.status === 200) {
        return resp.data;
      } else {
        showAlert({
          type: "error",
          message: resp?.response?.data?.error || "Server error",
        });
        return resp?.response?.data;
      }
    } catch (error) {
      showAlert({
        type: "error",
        message: error?.response?.data?.error || "Server error",
      });
    }
  };
  const callApi = async ({ method = "post", endpoint, data = {} }) => {
    try {
      if (!navigator.onLine) {
        return;
      }
      const resp = await axiosPrivate[method](endpoint, data);
      if (resp && resp.status === 200) {
        return resp.data;
      } else {
        showAlert({
          type: "error",
          message: resp?.response?.data?.error || "Server error",
        });
        return resp?.response?.data;
      }
    } catch (error) {
      showAlert({
        type: "error",
        message: error?.response?.data?.error || "Server error",
      });
    }
  };

  return (
    <ApisContext.Provider
      value={{
        asyncGet,
        asyncGetList,
        asyncSearchList,
        asyncGetListDeleted,
        asyncPostData,
        asyncPutData,
        asyncDelete,
        asyncGetReport,
        uploadFile,
        callApi,
      }}
    >
      {children}
    </ApisContext.Provider>
  );
}

export default ApisProvider;
