import React from "react";
import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { menus } from "../../../utils/menu";
import { useLocation, useNavigate } from "react-router-dom";
import { TbLogout2 } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../redux/actions/auth.action";
import useConfirmContext from "../../../hooks/hookContext/useConfirmContext";

function Main({ topHeight }) {
  const showConfirm = useConfirmContext();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    showConfirm({
      title: "Xác nhận",
      content: "Bạn có chắc muốn đăng xuất không ?",
      onConfirm: () => {
        logoutUser(dispatch);
      },
    });
  };

  return (
    <Box sx={{ height: `calc(100vh - ${topHeight})`, overflow: "auto" }}>
      {menus.map((menu) => {
        return (
          <React.Fragment key={menu.id}>
            <List>
              {menu.subs.map((sub) => {
                const isActive = location.pathname.indexOf(sub.path) >= 0;
                return (
                  <ListItemButton
                    key={sub.id}
                    onClick={sub.path ? () => navigate(sub.path) : sub.onClick}
                    sx={{
                      backgroundColor: isActive ? "primary.100" : "",
                      "&:hover": {
                        backgroundColor: isActive ? "primary.100" : "",
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "40px",
                        color: isActive ? "primary.main" : "",
                      }}
                    >
                      {sub.icon}
                    </ListItemIcon>
                    <ListItemText>{sub.text}</ListItemText>
                  </ListItemButton>
                );
              })}
            </List>
            <Divider />
          </React.Fragment>
        );
      })}
      <ListItemButton onClick={handleLogout}>
        <ListItemIcon
          sx={{
            minWidth: "40px",
            color: "error.main",
          }}
        >
          <TbLogout2 size={20} />
        </ListItemIcon>
        <ListItemText sx={{ color: "error.main" }}>Đăng xuất</ListItemText>
      </ListItemButton>
    </Box>
  );
}

export default Main;
