import { useEffect } from "react";
import useToken from "./useToken";
import { useNavigate } from "react-router-dom";
import { axiosPrivate } from "../utils/httpRequest";

const useAxiosPrivate = () => {
  const navigate = useNavigate();
  const token = useToken();

  const requestInterceptor = axiosPrivate.interceptors.request.use(function (
    config
  ) {
    if (!config.url.includes("access_token")) {
      config.url +=
        (config.url.includes("?") ? "&" : "?") + `access_token=${token}`;
    }
    return config;
  });

  const responseInterceptor = axiosPrivate.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      if (error?.response?.status === 401) {
        navigate("/login");
      } else {
        return error;
      }
    }
  );
  useEffect(() => {
    return () => {
      axiosPrivate.interceptors.request.eject(requestInterceptor);
      axiosPrivate.interceptors.response.eject(responseInterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  return axiosPrivate;
};
export default useAxiosPrivate;
