import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { FaClock } from "react-icons/fa";
import { formatDateDisplay } from "../../utils/helpers";
import moment from "moment";
import { TimeClock } from "@mui/x-date-pickers";
import { MdOutlineArrowBackIosNew } from "react-icons/md";

function DateTimeInput({
  disabled,
  disablePast,
  disableFuture,
  disableHighlightToday,
  datePickerProps = {},
  defaultValue,
  onChange,
}) {
  const [open, setOpen] = useState();
  const [view, setView] = useState("date"); // date, time
  const [datetime, setDateTime] = useState();

  const handleClose = () => {
    setOpen(false);
    setView("date");
  };

  const handleApply = () => {
    onChange(moment(datetime).toISOString());
    handleClose();
  };

  useEffect(() => {
    setDateTime(moment(defaultValue));
  }, [defaultValue]);

  return (
    <>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { width: "100vw", maxHeight: "80vh" } }}
      >
        <Box
          sx={{
            backgroundColor: "background.paper",
            width: "100%",
          }}
        >
          <Container>
            {view === "date" ? (
              <DateCalendar
                disabled={disabled}
                disablePast={disablePast}
                disableFuture={disableFuture}
                disableHighlightToday={disableHighlightToday}
                value={datetime}
                sx={{
                  margin: 0,
                  width: "100%",
                  "& .MuiDayCalendar-header": {
                    justifyContent: "space-between",
                  },
                  "& .MuiDayCalendar-weekContainer": {
                    justifyContent: "space-between",
                  },
                  "& .MuiYearCalendar-root": {
                    width: "100%",
                  },
                }}
                slotProps={{
                  calendarHeader: { sx: { padding: 0 } },
                  day: {
                    sx: {
                      width: "42px",
                      height: "42px",
                      "&.Mui-selected": { color: "common.white" },
                    },
                  },
                  yearButton: {
                    sx: {
                      "&.Mui-selected": { color: "common.white" },
                    },
                  },
                }}
                dayOfWeekFormatter={(date) => date.format("ddd")}
                onChange={(day, section) => {
                  if (section === "finish") {
                    setView("time");
                    setDateTime(day);
                  }
                }}
                {...datePickerProps}
              />
            ) : (
              <Stack sx={{ paddingTop: 1 }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <IconButton
                    onClick={() => setView("date")}
                    sx={{
                      color: "common.white",
                      backgroundColor: "primary.main",
                      width: "45px",
                      height: "45px",
                      "&:hover": {
                        backgroundColor: "primary.main",
                      },
                    }}
                  >
                    <MdOutlineArrowBackIosNew size={20} />
                  </IconButton>
                  <Typography>
                    <Typography
                      component="span"
                      sx={{ color: "text.secondary" }}
                    >
                      {moment(datetime).format("DD/MM/YYYY")}
                    </Typography>{" "}
                    <Typography component="span">
                      {moment(datetime).format("HH:mm")}
                    </Typography>
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={handleApply}
                    sx={{
                      height: "45px",
                      color: "common.white",
                    }}
                  >
                    xong
                  </Button>
                </Stack>
                <TimeClock
                  sx={{ margin: 0, width: "100%", maxHeight: "unset" }}
                  minutesStep={1}
                  value={datetime}
                  onChange={(day) => {
                    setDateTime(day);
                  }}
                />
              </Stack>
            )}
          </Container>
        </Box>
      </Drawer>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ height: "42px", padding: "0px", minHeight: "unset" }}
        component={MenuItem}
        onClick={() => setOpen(true)}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing="10px"
          sx={{ color: "text.secondary" }}
        >
          <FaClock size={18} />
          <Typography sx={{ color: "text.primary" }}>
            {formatDateDisplay(moment(datetime), "DD/MM/YYYY HH:mm")}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
}

export default DateTimeInput;
