import React, { createContext, useContext, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import AppBar from "../../components/appbar/AppBar";
import { MdClose } from "react-icons/md";
import useConfirmContext from "../../hooks/hookContext/useConfirmContext";
import { useNavigate } from "react-router-dom";
import Customer from "./components/customer/Customer";
import { useSelector } from "react-redux";
import moment from "moment";
import ForceCustomer from "./components/force-customer/ForceCustomer";
import DateTimeInput from "../../components/input/DateTimeInput";
import NumberInput from "../../components/input/NumberInput";
import AreaInput from "../../components/input/AreaInput";
import useAlertContext from "../../hooks/hookContext/useAlertContext";
import useApisContext from "../../hooks/hookContext/useApisContext";
import useBackdropContext from "../../hooks/hookContext/useBackdropContext";

const PtContext = createContext();

function Ptpage() {
  const showAlert = useAlertContext();
  const showConfirm = useConfirmContext();
  const { asyncPostData } = useApisContext();
  const [, setBackdrop] = useBackdropContext();
  const { currentStore } = useSelector((state) => state.store);
  const [phieuThu, setPhieuThu] = useState({
    ma_kh: "",
    ten_kh: "",
    tk_no: "1131",
    gia_tri: 0,
    dien_giai: "",
    ngay_ct: moment().toISOString(),
  });
  const navigate = useNavigate();

  const handleConfirm = () => {
    navigate(-1);
  };

  const handleUpdatePhieuThu = ({ ...updateValue }) => {
    setPhieuThu((prev) => ({ ...prev, ...updateValue }));
  };

  const generateDataPost = () => {
    const { gia_tri, ...fields } = phieuThu;
    const result = {
      ...fields,
      trang_thai: 0,
      ma_kho: currentStore?.ma_kho || "",
      ten_kho: currentStore?.ten_kho || "",
      details: [
        {
          ma_kh: fields?.ma_kh || "",
          tien_nt: gia_tri,
          tk_co: "1131",
        },
      ],
    };
    return result;
  };

  const handleSave = async () => {
    try {
      setBackdrop(true);
      const dataPost = generateDataPost();
      await asyncPostData({ apiCode: "pt1", data: dataPost });
    } catch (error) {
      showAlert({
        type: "error",
        message: error?.message || "Something went wrong!",
      });
    } finally {
      setBackdrop(false);
      navigate(-1);
    }
  };

  return (
    <PtContext.Provider value={{ phieuThu, handleUpdatePhieuThu }}>
      <ForceCustomer />
      <Box sx={{ width: "100vw", height: "100vh" }}>
        <AppBar>
          <Stack
            sx={{ height: "100%" }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <IconButton
                onClick={() => {
                  showConfirm({
                    title: "Hủy phiếu thu",
                    content: "Bạn có chắc muốn hủy phiếu thu này không ?",
                    onConfirm: handleConfirm,
                  });
                }}
                sx={{ color: "common.white", padding: 0 }}
              >
                <MdClose size={25} />
              </IconButton>
              <Typography
                variant="h1"
                sx={{ color: "common.white", flexGrow: 1 }}
              >
                Lập phiếu thu
              </Typography>
            </Stack>
            <Button onClick={handleSave} sx={{ color: "common.white" }}>
              Lưu
            </Button>
          </Stack>
        </AppBar>
        <Box
          sx={{
            width: "100vw",
            height: "calc(100vh - 60px)",
            overflow: "auto",
          }}
        >
          <Customer />
          <Divider />
          <Box sx={{ backgroundColor: "background.paper" }}>
            <Container>
              <DateTimeInput
                defaultValue={phieuThu?.ngay_ct}
                onChange={(val) => {
                  handleUpdatePhieuThu({ ngay_ct: val });
                }}
              />
            </Container>
          </Box>
          <Divider />
          <Box sx={{ backgroundColor: "background.paper", mt: 2 }}>
            <Container>
              <NumberInput
                title="Tiền khách trả"
                titleSx={{ fontWeight: 500 }}
                value={phieuThu?.gia_tri}
                onChange={(e) => {
                  handleUpdatePhieuThu({
                    gia_tri: Number(e.target.value) || 0,
                  });
                }}
              />
            </Container>
          </Box>
          <Box sx={{ backgroundColor: "background.paper", mt: 2, pt: 1 }}>
            <Container>
              <AreaInput
                placeholder="Ghi chú phiếu thu"
                minRows={3}
                value={phieuThu?.dien_giai}
                onChange={(e) =>
                  handleUpdatePhieuThu({ dien_giai: e.target.value })
                }
              />
            </Container>
          </Box>
        </Box>
      </Box>
    </PtContext.Provider>
  );
}

export default Ptpage;

export const usePtContext = () => {
  const value = useContext(PtContext);
  if (!value) throw new Error("PtContext must be used inside PtProvider");
  return value;
};
