import { useContext } from "react";
import { ApisContext } from "../../contexts/apis-context/ApisProvider";

const useApisContext = () => {
  const value = useContext(ApisContext);
  if (!value) {
    throw new Error("ApisContext must be used inside ApisProvider");
  }
  return value;
};
export default useApisContext;
