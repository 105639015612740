import React from "react";
import ThemeProvider from "./theme-context/ThemeProvider";
import AlertProvider from "./alert-context/AlertProvider";
import TipProvider from "./tip-context/TipProvider";
import BackdropProvider from "./backdropContext/BackdropProvider";
import ApisProvider from "./apis-context/ApisProvider";
import ConfirmProvider from "./confirm-context/ConfirmProvider";

function ContextProvider({ children }) {
  return (
    <ThemeProvider>
      <AlertProvider>
        <ConfirmProvider>
          <TipProvider>
            <BackdropProvider>
              <ApisProvider>{children}</ApisProvider>
            </BackdropProvider>
          </TipProvider>
        </ConfirmProvider>
      </AlertProvider>
    </ThemeProvider>
  );
}

export default ContextProvider;
