import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  Stack,
  TextField,
} from "@mui/material";
import { MdArrowBack, MdClose } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import useApisContext from "../../hooks/hookContext/useApisContext";
import useAlertContext from "../../hooks/hookContext/useAlertContext";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { isArray, cloneDeep } from "lodash";
import { v4 } from "uuid";

function SearchApiDrawer({
  open,
  onClose,
  searchPlaceholder,
  apiCode = "dmvt",
  searchFields = [],
  withIdApp = true,
  condition = {},
  getOptionLabel, // option => {}
  filterOptions, // (option, value) => {}
  renderOption, // (option, state) => {}
  isOptionEqualToValue, // (option, value) => true || false
  selectedValue,
  onChange,
  closeOnSelect = true,
  fixedScrollHeight,
  renderTopList,
  renderBottomList,
  reselectToClear = true,
}) {
  const showAlert = useAlertContext();
  const { asyncGetList } = useApisContext();
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const timerRef = useRef();

  const handleGetData = async (searchValue, page, oldProps = []) => {
    try {
      if (loading) return;
      setLoading(true);
      const selfCondition = {
        page,
        limit: 20,
        q: cloneDeep(condition),
      };
      if (searchValue === "" || searchValue) {
        selfCondition.q.$or = [];
        searchFields.forEach((searchFiled) =>
          selfCondition.q.$or.push({
            [searchFiled]: {
              $regex: searchValue.split(" ").join(".*"),
              $options: "i",
            },
          })
        );
        // selfCondition.q.$or.push({ $text: { $search: searchValue } });
      }
      const resp = await asyncGetList({
        apiCode,
        condition: selfCondition,
        withIdApp,
      });
      const respCount = await asyncGetList({
        apiCode,
        condition: { ...selfCondition, count: 1 },
        withIdApp,
      });
      setCount(respCount?.rows_number || 0);
      if (resp && Array.isArray(resp)) {
        setOptions([...oldProps, ...resp]);
      }
    } catch (error) {
      showAlert({
        type: "error",
        message: error?.message || "Something went wrong!",
      });
    } finally {
      setLoading(false);
      setPage(page + 1);
    }
  };
  const hasNextPage = options.length < count;

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => handleGetData(search, page, options),
  });
  const handleReset = () => {
    setSearchText("");
    setOptions([]);
    setPage(1);
    setCount(0);
  };

  const handleClose = () => {
    handleReset();
    onClose?.();
  };

  const handleChange = (newValue) => {
    if (!reselectToClear && !newValue) return;
    onChange?.(newValue);
    if (closeOnSelect) {
      handleClose();
    }
  };

  const renderLoading = (sentryRef) => {
    return (
      <ListItem
        ref={sentryRef}
        sx={{ display: "flex", alignItems: "stretch", gap: 1, height: "45px" }}
      >
        <Skeleton
          variant="rounded"
          sx={{ width: "45px", height: "100%" }}
          animation="wave"
        />
        <Skeleton
          variant="rounded"
          sx={{ flexGrow: 1, height: "100%" }}
          animation="wave"
        />
      </ListItem>
    );
  };

  useEffect(() => {
    if (open) {
      handleGetData(search, 1, []);
      setPage(1);
      setOptions([]);
      setCount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, open, condition]);
  useEffect(() => {
    timerRef.current = setTimeout(() => {
      setSearch(searchText.trim());
    }, 800);
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [searchText]);

  useEffect(() => {
    setOptions([]);
  }, [apiCode]);

  return (
    <Drawer
      open={open}
      onClose={!!onClose ? handleClose : undefined}
      anchor="top"
      PaperProps={{ sx: { backgroundColor: "transparent", width: "100vw" } }}
    >
      <Box
        sx={{
          backgroundColor: "background.paper",
          width: "100vw",
          maxWidth: "100vw",
        }}
      >
        <Container>
          <Stack
            sx={{ width: "100%", height: "50px" }}
            direction="row"
            alignItems="center"
            spacing={2}
          >
            {!!onClose && (
              <IconButton sx={{ padding: "0px" }} onClick={handleClose}>
                <MdArrowBack size={25} />
              </IconButton>
            )}
            <TextField
              fullWidth
              placeholder={searchPlaceholder}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              InputProps={{
                autoComplete: "off",
                endAdornment: (
                  <Stack direction="row">
                    {!searchText ? (
                      <IconButton sx={{ padding: "0px" }}>
                        <CiSearch size={25} />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => setSearchText("")}
                        sx={{ padding: "0px" }}
                      >
                        <MdClose size={25} />
                      </IconButton>
                    )}
                  </Stack>
                ),
              }}
              sx={{
                height: "100%",
                "& .MuiInputBase-root": {
                  paddingRight: "0px",
                  "& .MuiInputBase-input": {
                    height: "50px",
                    caretColor: "green",
                    px: 0,
                  },
                  "& fieldset": {
                    border: "none",
                  },
                },
              }}
            />
          </Stack>
        </Container>
        <Divider sx={{ height: "1px" }} />
        {!!renderTopList && renderTopList()}
        <List
          sx={{
            height: fixedScrollHeight || "calc(100vh - 50px - 1px)",
            overflow: "auto",
          }}
        >
          {options?.length === 0 && loading && renderLoading()}
          {isArray(options) &&
            options.map((option) => {
              const key = option._id || option.id || v4();
              const isEqual = !!isOptionEqualToValue
                ? isOptionEqualToValue(option, selectedValue)
                : !!selectedValue &&
                  getOptionLabel?.(option) === getOptionLabel(selectedValue);

              if (renderOption) {
                return renderOption(option, {
                  key,
                  inputValue: searchText,
                  isEqual,
                  onClick: () => handleChange(option),
                });
              }
              return (
                <ListItemButton
                  key={key}
                  onClick={() => handleChange(isEqual ? null : option)}
                  sx={{
                    backgroundColor: isEqual ? "primary.100" : "",
                    "&:hover": {
                      backgroundColor: isEqual ? "primary.100" : "",
                    },
                  }}
                >
                  <ListItemText>
                    {getOptionLabel?.(option) || "Option"}
                  </ListItemText>
                </ListItemButton>
              );
            })}
          {(count || 0) > (options?.length || 0) && renderLoading(sentryRef)}
        </List>
        {!!renderBottomList && renderBottomList()}
      </Box>
    </Drawer>
  );
}

export default SearchApiDrawer;
