import * as React from "react";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    font-family: Inter;
    font-size: 16px;
    resize: none;
    border: 1px solid ${theme.palette.divider};
    };


    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

export default function AreaInput({
  placeholder,
  minRows = 3,
  value,
  onChange,
  name,
  register = () => {},
  ...props
}) {
  return (
    <Textarea
      minRows={minRows}
      spellCheck={false}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      {...register(name)}
      {...props}
    />
  );
}
