import React from "react";
import { Box, Container } from "@mui/material";

function AppBar({ children }) {
  return (
    <Box
      component="header"
      sx={{ height: "60px", backgroundColor: "primary.main" }}
    >
      <Container sx={{ width: "100%", height: "100%" }}>{children}</Container>
    </Box>
  );
}

export default AppBar;
