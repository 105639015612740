import React, { useState } from "react";
import MainLayout from "../../components/layouts/main-layout/MainLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Container,
  Divider,
  IconButton,
  List,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import { numeralCustom } from "../../utils/helpers";
import { useSwipeable } from "react-swipeable";
import { BsTrash } from "react-icons/bs";
import { updateTempHds } from "../../redux/reducers/order.reducer";
import { useNavigate } from "react-router-dom";

function OrderTempPage() {
  const { tempHds } = useSelector((state) => state.order);
  const [openDelete, setOpenDelete] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSwipedLeft = () => {
    setOpenDelete(true);
  };

  const handleSwipedRight = () => {
    setOpenDelete(false);
  };

  const swipeHandler = useSwipeable({
    onSwipedLeft: handleSwipedLeft,
    onSwipedRight: handleSwipedRight,
  });

  const handleDeleteTemp = (hd) => {
    if (!hd) return;
    const newTempHds = tempHds.filter((item) => item._id !== hd._id);
    dispatch(updateTempHds(newTempHds));
  };

  const handleSelectHd = (hd) => {
    handleDeleteTemp(hd);
    navigate("/order", {
      state: hd,
    });
  };

  return (
    <MainLayout title={`Hóa Đơn Tạm (${tempHds?.length || 0})`}>
      <Box
        sx={{ width: "100vw", height: "calc(100vh - 60px)", overflow: "auto" }}
      >
        <Box sx={{ marginTop: 1, backgroundColor: "background.paper" }}>
          {tempHds && tempHds.length > 0 && (
            <List>
              {tempHds.map((hd, index) => {
                return (
                  <React.Fragment key={hd._id}>
                    <ListItemButton
                      key={hd._id}
                      sx={{
                        padding: 0,
                        minHeight: "45px",
                        position: "relative",
                      }}
                      onClick={() => handleSelectHd(hd)}
                      {...swipeHandler}
                    >
                      {openDelete && (
                        <Box
                          sx={{
                            position: "absolute",
                            inset: 0,
                            backgroundColor: "#00000066",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenDelete(false);
                          }}
                        >
                          <Stack direction="row" justifyContent="flex-end">
                            <IconButton
                              sx={{
                                color: "common.white",
                                borderRadius: "0px",
                                width: "45px",
                                height: "45px",
                                backgroundColor: "error.main",
                                "&:hover": {
                                  backgroundColor: "error.main",
                                },
                              }}
                              onClick={() => handleDeleteTemp(hd)}
                            >
                              <BsTrash size={25} />
                            </IconButton>
                          </Stack>
                        </Box>
                      )}
                      <Container>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Typography>{index + 1}.</Typography>
                            <Typography>{hd.ten_kh}</Typography>
                          </Stack>
                          <Typography
                            sx={{ color: "primary.main", fontWeight: 500 }}
                          >
                            {numeralCustom(hd.t_tien || 0).format()}
                          </Typography>
                        </Stack>
                      </Container>
                    </ListItemButton>
                    <Divider />
                  </React.Fragment>
                );
              })}
            </List>
          )}
        </Box>
      </Box>
    </MainLayout>
  );
}

export default OrderTempPage;
