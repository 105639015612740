import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
  name: "order",
  initialState: {
    currentHd: null,
    tempHds: [],
    selectedProduct: null,
    customer: null,
    selectMulti: false,
  },
  reducers: {
    updateCurrentHd(state, action) {
      const { payload: newHd } = action;
      let tongTienHang = 0;
      let tongTienCk = 0;
      let tongThue = 0;
      let tienCkHd = 0;

      newHd.details.forEach((detail) => {
        tongTienHang += detail.tien || 0;
        tongTienCk += detail.tien_ck || 0;
        tongThue += detail.tien_thue || 0;
      });
      if (
        tongTienHang !== (state.currentHd?.t_tien || 0) ||
        newHd.ma_kh !== state.currentHd?.ma_kh
      ) {
        newHd.tien_ck_hd = 0;
        newHd.ty_le_ck_hd = 0;
        newHd.exfields = {};
      }
      const chietkhaus = newHd?.exfields?.dmchietkhau || [];
      if (chietkhaus.length > 0) {
        tienCkHd = chietkhaus.reduce((acc, item) => {
          if ((item.ty_le_ck_hd || 0) > 0) {
            let tienCk = ((item.ty_le_ck_hd || 0) * tongTienHang) / 100;
            tienCk = tienCk > item.tien_ck ? item.tien_ck : tienCk;
            acc += tienCk;
            return acc;
          } else {
            acc += item.tien_ck || 0;
            return acc;
          }
        }, 0);
      } else {
        tienCkHd = ((newHd?.ty_le_ck_hd || 0) * tongTienHang) / 100;
      }
      newHd.tien_ck_hd = tienCkHd;
      tongTienCk += newHd.tien_ck_hd || 0;
      const tongThanhToan = tongTienHang - tongTienCk + tongThue;
      state.currentHd = {
        ...newHd,
        t_tien: tongTienHang,
        t_tien_nt: tongTienHang,
        tc_ck: tongTienCk,
        tc_ck_nt: tongTienCk,
        t_thue: tongThue,
        t_thue_nt: tongThue,
        t_tt: tongThanhToan,
        t_tt_nt: tongThanhToan,
      };
    },
    updateCustomer(state, action) {
      state.customer = action.payload;
    },
    updateSelectMulti(state, action) {
      state.selectMulti = action.payload;
    },
    updateTempHds(state, action) {
      state.tempHds = action.payload;
    },
    updateSelectedProduct(state, action) {
      state.selectedProduct = action.payload;
    },
    resetOrder(state) {
      state.currentHd = null;
      state.customer = null;
    },
  },
});

export const {
  updateCurrentHd,
  updateCustomer,
  updateSelectMulti,
  updateTempHds,
  updateSelectedProduct,
  resetOrder,
} = orderSlice.actions;
export default orderSlice.reducer;
