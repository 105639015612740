import React, { useState } from "react";
import MainLayout from "../../components/layouts/main-layout/MainLayout";
import { Box, Button, Container, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { cloneDeep } from "lodash";
import useApisContext from "../../hooks/hookContext/useApisContext";
import useToken from "../../hooks/useToken";
import useAlertContext from "../../hooks/hookContext/useAlertContext";
import { updateProfile } from "../../redux/actions/auth.action";
import ImageInput from "../../components/input/ImageInput";
import useLinkImage from "../../hooks/useLinkImage";
import TextInput from "../../components/input/TextInput";
import { LoadingButton } from "@mui/lab";
import DrawerChangePassword from "./components/DrawerChangePassword";

const schema = yup.object({
  name: yup.string().required("Vui lòng nhập họ và tên"),
  email2: yup.string().email("Email không đúng định dạng"),
});

function AccountPage() {
  const token = useToken();
  const showAlert = useAlertContext();
  const { uploadFile } = useApisContext();
  const generateLink = useLinkImage();
  const profileData = useSelector((state) => state.auth.profile);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onSubmit",
    defaultValues: profileData || "",
    resolver: yupResolver(schema),
  });
  const [picture, setPicture] = useState(null);
  const [openChangePassword, setOpenChangePassword] = useState(false);

  const dispatch = useDispatch();

  const handleUpdate = async (values) => {
    const dataPost = cloneDeep(values);
    if (picture) {
      const formData = new FormData();
      formData.append("file", picture);
      const resp = await uploadFile({ formData, folder: "avatars", token });
      dataPost.picture = resp?.fileUrl;
    } else if (picture === undefined) {
      dataPost.picture = "";
    }
    updateProfile({ data: dataPost, token, dispatch, showAlert });
  };

  return (
    <>
      <DrawerChangePassword
        open={openChangePassword}
        onClose={() => setOpenChangePassword(false)}
      />
      <MainLayout
        title="Cài đặt tài khoản"
        showCreateHd={false}
        slotComponent={
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ flex: 1, height: "100%" }}
          >
            <LoadingButton
              loading={isSubmitting}
              sx={{ color: "common.white" }}
              onClick={handleSubmit(handleUpdate)}
            >
              Lưu
            </LoadingButton>
          </Stack>
        }
      >
        <Box
          sx={{
            width: "100%",
            height: "calc(100vh - 60px)",
            overflow: "auto",
            py: 1,
          }}
        >
          <Stack
            spacing={1}
            sx={{ width: "100%", backgroundColor: "background.paper", py: 2 }}
          >
            <Stack
              direction="row"
              alignItems="cener"
              justifyContent="center"
              sx={{ pb: 2 }}
            >
              <Box sx={{ width: "200px", height: "200px" }}>
                <ImageInput
                  url={generateLink(profileData?.picture)}
                  onChange={setPicture}
                />
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="center">
              <Button
                variant="text"
                sx={{ height: "45px", width: "fit-content" }}
                onClick={() => setOpenChangePassword(true)}
              >
                Đổi mật khẩu
              </Button>
            </Stack>
            <Container>
              <TextInput
                label="Họ và tên"
                required
                placeholder="Họ và tên"
                name="name"
                register={register}
                errorMessage={errors?.name?.message}
              />
            </Container>
            <Container>
              <TextInput
                readOnly
                label="Tài khoản"
                placeholder="Tài khoản người dùng"
                register={register}
                name="email"
              />
            </Container>
            <Container>
              <TextInput
                placeholder="Số điện thoại"
                label="Điện thoại"
                register={register}
                name="phone"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              />
            </Container>
            <Container>
              <TextInput
                placeholder="Số điện thoại đăng ký zalo"
                label="Zalo"
                register={register}
                name="zalo"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              />
            </Container>
            <Container>
              <TextInput
                placeholder="Link trang cá nhân facebook"
                label="Facebook"
                register={register}
                name="facebook"
              />
            </Container>
            <Container>
              <TextInput
                placeholder="Địa chỉ hiện tại"
                label="Địa chỉ"
                register={register}
                name="address"
              />
            </Container>
            <Container>
              <TextInput
                placeholder="Email khôi phục mật khẩu"
                label="Email"
                register={register}
                name="email2"
                errorMessage={errors?.email2?.message}
              />
            </Container>
          </Stack>
        </Box>
      </MainLayout>
    </>
  );
}

export default AccountPage;
