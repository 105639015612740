import React, { useId } from "react";
import { TextField } from "@mui/material";
import WrapperInput from "./WrapperInput";

function TextInput({
  type = "text",
  name,
  value,
  onChange,
  label,
  errorMessage,
  required = false,
  placeholder,
  disabled = false,
  textFieldSx = {},
  register = () => {},
  defaultValue,
  textFieldProps = {},
  InputProps = {},
  inputProps = {},
  labelWidth = "30%",
  labelSx = {},
  variant = "standard",
  readOnly,
  count,
  ...props
}) {
  const id = useId();

  return (
    <WrapperInput
      id={id}
      label={label}
      labelWidth={labelWidth}
      required={required}
      errorMessage={errorMessage}
      labelSx={labelSx}
      count={count}
      {...props}
    >
      <TextField
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        placeholder={placeholder}
        disabled={disabled}
        name={name}
        spellCheck={false}
        {...register(name)}
        inputProps={{ ...inputProps }}
        InputProps={{
          readOnly: readOnly,
          ...InputProps,
        }}
        fullWidth
        sx={{
          "& .MuiInputBase-root": {
            paddingLeft: 0,
          },
          "& .MuiFormLabel-root": {
            fontSize: "12px",
            fontWeight: 600,
            color: "primary.main",
            paddingRight: "5px",
            transform: "translate(14px, -12px)",
            backgroundColor: "whitish.pureWhite",
          },
          "& .MuiInputBase-input": {
            height: "42px",
          },
          ...textFieldSx,
        }}
        variant={variant}
        autoComplete="off"
        {...textFieldProps}
      />
    </WrapperInput>
  );
}

export default TextInput;
