import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import AppBar from "../../../../../components/appbar/AppBar";
import { MdClose } from "react-icons/md";
import Quantity from "../../number/Quantity";
import { numeralCustom } from "../../../../../utils/helpers";
import useAlertContext from "../../../../../hooks/hookContext/useAlertContext";
import useApisContext from "../../../../../hooks/hookContext/useApisContext";
import { cloneDeep, isArray } from "lodash";
import useLinkImage from "../../../../../hooks/useLinkImage";
import ProductImage from "../../../../../assets/images/product.png";
import { useOrderContext } from "../../../OrderPage";
import { useSelector } from "react-redux";
import AreaInput from "../../../../../components/input/AreaInput";

function DetailInfo({ open, onClose, data, handleDeleteDetail }) {
  const generateLink = useLinkImage();
  const showAlert = useAlertContext();
  const { asyncSearchList } = useApisContext();
  const { handleUpdateCurrentHd } = useOrderContext();
  const { currentHd } = useSelector((state) => state.order);
  const [localData, setLocalData] = useState(data);
  const [product, setProduct] = useState();

  const handleNumberChange = (val) => {
    setLocalData((prev) => {
      const prevClone = cloneDeep(prev);
      prevClone.sl_xuat = val || 0;
      prevClone.tien = (prevClone.sl_xuat || 0) * (prevClone.gia_ban || 0);
      prevClone.tien_xuat = (prevClone.tien || 0) - (prevClone.tien_ck || 0);
      return prevClone;
    });
  };

  const handleSave = () => {
    const currentHdClone = cloneDeep(currentHd);
    const index = (currentHdClone?.details || []).findIndex(
      (item) => item._id === data?._id
    );
    currentHdClone.details.splice(index, 1, localData);

    handleUpdateCurrentHd({ details: currentHdClone?.details || [] });
    onClose();
  };

  const handleGetOriginProduct = async () => {
    try {
      const resp = await asyncSearchList({
        apiCode: "dmvt",
        condition: { page: 1, limit: 1, q: { ma_vt: data.ma_vt } },
      });
      if (resp && isArray(resp) && resp.length === 1) {
        setProduct(resp[0]);
      }
    } catch (error) {
      showAlert({
        type: "error",
        message: error?.message || "Something went wrong!",
      });
    }
  };

  useEffect(() => {
    if (open) {
      handleGetOriginProduct();
      setLocalData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, open]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: "100vw", height: "100vh" } }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "background.default",
        }}
      >
        <AppBar>
          <Stack
            direction="row"
            alignItems="center"
            spacing="10px"
            sx={{ width: "100%", height: "100%" }}
          >
            <IconButton
              onClick={onClose}
              sx={{ color: "common.white", padding: 0 }}
            >
              <MdClose size={25} />
            </IconButton>
            <Typography
              variant="h1"
              sx={{ color: "common.white", flexGrow: 1 }}
            >
              {product?.ma_vt}
            </Typography>
            <Button sx={{ color: "common.white" }} onClick={handleSave}>
              Lưu
            </Button>
          </Stack>
        </AppBar>
        <Stack
          spacing={2}
          sx={{ height: "calc(100vh - 60px - 45px)", overflow: "auto" }}
        >
          <Box sx={{ py: 1, backgroundColor: "background.paper" }}>
            <Container>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Box
                    sx={{
                      width: "100%",
                      paddingTop: "100%",
                      position: "relative",
                    }}
                  >
                    <Avatar
                      src={
                        product?.picture_thumb
                          ? generateLink(product.picture_thumb)
                          : ProductImage
                      }
                      alt="product image"
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        borderRadius: "0px",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={10}>
                  <Stack>
                    <Typography>{product?.ten_vt}</Typography>
                    <Typography
                      sx={{ fontSize: "13px", color: "text.secondary" }}
                    >
                      {product?.ten_dvt}
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography
                        sx={{ fontWeight: 500, color: "primary.main" }}
                      >
                        {numeralCustom(data.gia_ban).format()}
                      </Typography>
                      <Typography sx={{ fontSize: "13px" }}>
                        Tồn: {product?.ton00}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box sx={{ backgroundColor: "background.paper" }}>
            <Container sx={{ width: "100%" }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ py: 1 }}
              >
                <Typography>Số lượng</Typography>
                <Quantity
                  disabledMinus={localData?.sl_xuat <= 1}
                  defaultValue={localData?.sl_xuat || 0}
                  warning={localData?.sl_xuat > (localData.ton_kho || 0)}
                  onChange={handleNumberChange}
                />
              </Stack>
            </Container>
            <Divider />
            {/* <Container sx={{ width: "100%" }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ py: 1 }}
              >
                <Stack spacing="5px">
                  <Typography>
                    <Typography component="span">Lô</Typography>{" "}
                    <Chip component="span" label="KD003" color="secondary" />
                  </Typography>
                  <Typography
                    sx={{ fontSize: "13px", color: "text.secondary" }}
                  >
                    HSD: 22/12/2026
                  </Typography>
                  <Typography
                    sx={{ fontSize: "13px", color: "text.secondary" }}
                  >
                    Tồn: 9
                  </Typography>
                </Stack>
                <Quantity />
              </Stack>
            </Container> */}
            <Container>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: "100%", height: "100%", py: 1 }}
              >
                <Typography>Tiền hàng</Typography>
                <Typography sx={{ fontWeight: 500 }}>
                  {numeralCustom(localData?.tien_xuat || 0).format()} đ
                </Typography>
              </Stack>
            </Container>
            <Divider />
            <Container sx={{ pt: 1 }}>
              <AreaInput
                minRows={3}
                placeholder="Ghi chú sản phẩm"
                value={localData.dien_giai}
                onChange={(e) =>
                  setLocalData({
                    ...localData,
                    dien_giai: e.target.value || "",
                  })
                }
              />
            </Container>
          </Box>
        </Stack>
        <Button
          variant="contained"
          sx={{
            color: "common.white",
            height: "45px",
            width: "100vw",
            borderRadius: "0px",
            backgroundColor: "error.main",
            "&:hover": {
              backgroundColor: "error.main",
            },
          }}
          onClick={handleDeleteDetail}
        >
          Xóa
        </Button>
      </Box>
    </Drawer>
  );
}

export default DetailInfo;
