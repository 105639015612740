import { useContext } from "react";
import { AlertContext } from "../../contexts/alert-context/AlertProvider";

const useAlertContext = () => {
  const value = useContext(AlertContext);
  if (!value) {
    throw new Error("Alert context must be used inside alert provider");
  }
  return value;
};
export default useAlertContext;
