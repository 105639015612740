import React, { useEffect, useState } from "react";
import { Button, Stack, TextField } from "@mui/material";
import { numeralCustom } from "../../utils/helpers";

function ChangeModeInput({
  readOnly,
  width = "200px",
  defaultPercent = 0,
  defaultTien = 0,
  tienGoc = 0,
  onChietKhauChange = ({ ty_le_ck, tien_ck }) => {},
}) {
  const [isPercent, setIsPercent] = useState(false);
  const [giaTriChietKhau, setGiaTriChietKhau] = useState({
    ty_le_ck: defaultPercent,
    tien_ck: defaultTien,
  });

  const handleGiaTriChietKhauChange = (e) => {
    let value = numeralCustom(e.target.value).value();
    if (isPercent) {
      if (value > 100) {
        value = 100;
      }
      const tienCk = (tienGoc * value) / 100;
      setGiaTriChietKhau({ ty_le_ck: value, tien_ck: tienCk });
      onChietKhauChange({ ty_le_ck: value, tien_ck: tienCk });
    } else {
      if (value > tienGoc) {
        value = tienGoc;
      }
      const tyLeCk = (value * 100) / tienGoc;
      setGiaTriChietKhau({ ty_le_ck: tyLeCk, tien_ck: value });
      onChietKhauChange({ ty_le_ck: tyLeCk, tien_ck: value });
    }
  };

  useEffect(() => {
    setGiaTriChietKhau({
      ty_le_ck: defaultPercent,
      tien_ck: defaultTien,
    });
  }, [defaultPercent, defaultTien]);

  return (
    <Stack direction="row" alignItems="center">
      <TextField
        variant="outlined"
        type="text"
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        value={numeralCustom(
          isPercent ? giaTriChietKhau.ty_le_ck : giaTriChietKhau.tien_ck
        ).format()}
        onChange={handleGiaTriChietKhauChange}
        InputProps={{
          readOnly,
          startAdornment: (
            <Button
              variant="contained"
              sx={{ height: "35px", color: "common.white" }}
              onClick={() => setIsPercent(!isPercent)}
            >
              {isPercent ? "%" : "VND"}
            </Button>
          ),
        }}
        autoComplete="off"
        sx={{
          borderBottom: "2px solid",
          borderColor: "divider",
          width,
          "& .MuiInputBase-root": {
            paddingLeft: 0,
            "& .MuiInputBase-input": {
              padding: 0,
              pl: 1,
              height: "40px",
              textAlign: "right",
            },
            "& fieldset": {
              border: "none",
            },
          },
        }}
      />
    </Stack>
  );
}

export default ChangeModeInput;
