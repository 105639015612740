import React, { useState } from "react";
import { Box, Drawer, MenuItem, Stack, Typography } from "@mui/material";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import Stores from "./Stores";
import Main from "./Main";

const topHeigth = "70px";

function Menu({ open, onClose }) {
  const { profile: profileData } = useSelector((state) => state.auth);
  const { currentStore } = useSelector((state) => state.store);
  const [showStores, setShowStores] = useState(false);

  return (
    <Drawer open={open} onClose={onClose}>
      <Box sx={{ width: "80vw" }}>
        {/* top start */}
        <Box
          component={MenuItem}
          onClick={() => setShowStores(!showStores)}
          sx={{
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "5px",
            height: topHeigth,
            backgroundColor: "primary.main",
            px: 2,
            py: 1,
            "&:hover": {
              backgroundColor: "primary.main",
            },
          }}
        >
          <Typography
            variant="h3"
            sx={{ color: "common.white", fontWeight: 500 }}
          >
            {profileData?.name}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Typography sx={{ color: "common.white", fontWeight: 300 }}>
              {currentStore?.ten_kho}
            </Typography>
            {showStores ? (
              <FaCaretRight color="#fff" size={20} />
            ) : (
              <FaCaretDown color="#fff" size={20} />
            )}
          </Stack>
        </Box>
        {showStores ? (
          <Stores topHeight={topHeigth} />
        ) : (
          <Main topHeight={topHeigth} />
        )}
      </Box>
    </Drawer>
  );
}

export default Menu;
