import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import LogoImage from "../../assets/images/logo.svg";
import { FaUser } from "react-icons/fa";
import { BiSolidLockAlt } from "react-icons/bi";
import { BsEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { LoadingButton } from "@mui/lab";
import useTipContext from "../../hooks/hookContext/useTipContext";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import useAlertContext from "../../hooks/hookContext/useAlertContext";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../redux/actions/auth.action";

const schema = yup.object({
  username: yup.string().required("Vui lòng nhập tài khoản đăng nhập"),
  password: yup.string().required("Vui lòng nhập mật khẩu"),
});

function LoginPage() {
  const showAlert = useAlertContext();
  const showTip = useTipContext();
  const [isPassword, setIsPassword] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: { username: "", password: "" },
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleForgotPassword = () => {
    showTip({
      title: "Bạn quên mật khẩu ?",
      content: (
        <List disablePadding>
          <ListItem sx={{ padding: "4px 0" }}>
            <ListItemText
              sx={{ margin: 0, "& .MuiTypography-root": { fontWeight: 600 } }}
            >
              Bạn cần làm theo các bước sau:
            </ListItemText>
          </ListItem>
          <ListItem sx={{ padding: "4px 0" }}>
            <ListItemText sx={{ margin: 0 }}>
              1. Liên hệ đến quản lý để được cấp lại mật khẩu mới.
            </ListItemText>
          </ListItem>
          <ListItem sx={{ padding: "4px 0" }}>
            <ListItemText sx={{ margin: 0 }}>
              2. Đăng nhập lại bằng mật khẩu mới được cấp.
            </ListItemText>
          </ListItem>
          <ListItem sx={{ padding: "4px 0" }}>
            <ListItemText sx={{ margin: 0 }}>
              3. Sau khi đăng nhập thành công, hãy thay đổi mật khẩu mới theo ý
              bạn.
            </ListItemText>
          </ListItem>
        </List>
      ),
    });
  };
  const handleHasNoAccount = () => {
    showTip({
      title: "Bạn chưa có tài khoản ?",
      content: (
        <List disablePadding>
          <ListItem sx={{ padding: "4px 0" }}>
            <ListItemText
              sx={{ margin: 0, "& .MuiTypography-root": { fontWeight: 600 } }}
            >
              Bạn cần làm theo các bước sau:
            </ListItemText>
          </ListItem>
          <ListItem sx={{ padding: "4px 0" }}>
            <ListItemText sx={{ margin: 0 }}>
              1. Liên hệ đến quản lý để được cấp tài khoản.
            </ListItemText>
          </ListItem>
          <ListItem sx={{ padding: "4px 0" }}>
            <ListItemText sx={{ margin: 0 }}>
              2. Đăng nhập vào hệ thống bằng tài khoản được cấp.
            </ListItemText>
          </ListItem>
          <ListItem sx={{ padding: "4px 0" }}>
            <ListItemText sx={{ margin: 0 }}>
              3. Sau khi đăng nhập thành công, hãy thay đổi mật khẩu mới theo ý
              bạn.
            </ListItemText>
          </ListItem>
        </List>
      ),
    });
  };

  const handleLogin = async (values) => {
    await loginUser({ ...values, dispatch, navigate, showAlert });
  };

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        height: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        <Container>
          <Stack
            alignItems="center"
            spacing="10px"
            sx={{ width: "100%", padding: "20px 0" }}
          >
            <Stack
              sx={{
                padding: "10px",
                borderRadius: "10px",
                backgroundColor: "grey.A100",
              }}
            >
              <Avatar
                src={LogoImage}
                alt="logo image"
                sx={{ width: "60px", height: "60px" }}
              />
            </Stack>
            <Typography
              sx={{
                padding: "4px 8px",
                borderRadius: "6px",
                backgroundColor: "primary.100",
                color: "primary.main",
                fontWeight: 500,
              }}
            >
              DÀNH CHO NGƯỜI BÁN
            </Typography>
          </Stack>
        </Container>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          flexShrink: 0,
          width: "100%",
          height: "auto",
          backgroundColor: "primary.main",
        }}
      >
        <Container>
          <Stack spacing="20px" sx={{ padding: "50px 0" }}>
            <Stack direction="row" alignItems="flex-end" spacing="20px">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ color: "common.white" }}
              >
                <FaUser size={20} />
              </Stack>
              <TextField
                {...register("username")}
                variant="outlined"
                fullWidth
                label={errors?.username?.message || "Tài khoản đăng nhập"}
                InputProps={{ autoComplete: "off" }}
                InputLabelProps={{
                  sx: {
                    color: "grey.A100",
                    marginLeft: "-14px",
                    "&.Mui-focused": {
                      color: "grey.A100",
                    },
                  },
                }}
                sx={{
                  borderBottom: "2px solid",
                  borderColor: errors?.username?.message
                    ? "error.main"
                    : "divider",
                  "& .MuiInputBase-root": {
                    "& .MuiInputBase-input": {
                      color: "common.white",
                      height: "42px",
                      px: 0,
                      py: 1,
                    },
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
              />
            </Stack>

            <Stack direction="row" alignItems="flex-end" spacing="20px">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ color: "common.white" }}
              >
                <BiSolidLockAlt size={20} />
              </Stack>
              <TextField
                {...register("password")}
                fullWidth
                variant="outlined"
                label={errors?.password?.message || "Mật khẩu"}
                type={isPassword ? "password" : "text"}
                InputProps={{
                  autoComplete: "off",
                  endAdornment: (
                    <IconButton
                      onClick={() => setIsPassword(!isPassword)}
                      sx={{ color: "common.white" }}
                    >
                      {isPassword ? <BsFillEyeSlashFill /> : <BsEyeFill />}
                    </IconButton>
                  ),
                }}
                InputLabelProps={{
                  sx: {
                    color: "grey.A100",
                    marginLeft: "-14px",
                    "&.Mui-focused": {
                      color: "grey.A100",
                    },
                  },
                }}
                sx={{
                  borderBottom: "2px solid",
                  borderColor: errors?.password?.message
                    ? "error.main"
                    : "divider",
                  "& .MuiInputBase-root": {
                    "& .MuiInputBase-input": {
                      color: "common.white",
                      height: "42px",
                      px: 0,
                      py: 1,
                    },
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
              />
            </Stack>

            <Stack direction="row" justifyContent="flex-end">
              <Button
                onClick={handleForgotPassword}
                sx={{
                  color: "common.white",
                  textTransform: "none",
                  opacity: "0.8",
                }}
              >
                Quên mật khẩu ?
              </Button>
            </Stack>
            <Stack spacing="5px" alignItems="center">
              <LoadingButton
                loading={isSubmitting}
                onClick={handleSubmit(handleLogin)}
                sx={{
                  backgroundColor: "background.paper",
                  textTransform: "none",
                  width: "50vw",
                  height: "45px",
                  "&:hover": {
                    backgroundColor: "background.paper",
                  },
                }}
              >
                Đăng nhập
              </LoadingButton>
              <Button
                onClick={handleHasNoAccount}
                sx={{
                  opacity: "0.6",
                  color: "common.white",
                  textTransform: "none",
                }}
              >
                Bạn chưa có tài khoản ?
              </Button>
            </Stack>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}

export default LoginPage;
