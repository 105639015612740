import { PUBLIC_URL } from "../../utils/constants";
import { axiosPublic } from "../../utils/httpRequest";
import { persistor } from "../reducers/root.reducer";
import {
  loginStart,
  loginFail,
  loginSuccess,
  logoutSuccess,
  editProfile,
  updateRights,
  updateUser,
} from "../reducers/auth.reducer";
import { Buffer } from "buffer";

// login user
const loginUser = async ({
  username,
  password,
  dispatch,
  navigate,
  showAlert,
}) => {
  try {
    dispatch(loginStart());
    const credentials = Buffer.from(username + ":" + password).toString(
      "base64"
    );
    const basicAuth = "Basic " + credentials;
    const resp = await axiosPublic.get(`${PUBLIC_URL}/auth/local`, {
      headers: {
        Authorization: basicAuth,
      },
    });
    if (resp && resp.status === 200) {
      localStorage.setItem("token", resp.data.token);
      dispatch(loginSuccess(resp.data));
      navigate("/dashboard");
    }
  } catch (error) {
    dispatch(loginFail());
    showAlert({
      type: "error",
      message: error?.response?.data?.message || "Server error",
    });
    return error;
  }
};
// logout user
const logoutUser = (dispatch) => {
  dispatch(logoutSuccess());
  localStorage.clear();
  persistor.pause();
  persistor.flush().then(() => {
    window.location.reload();
    return persistor.purge();
  });
};

// get profile
const getProfile = async ({ dispatch, token, showAlert }) => {
  try {
    const resp = await axiosPublic.get(`/api/profile?access_token=${token}`);
    if (resp && resp.status === 200) {
      dispatch(editProfile(resp.data));
    }
  } catch (error) {
    showAlert({
      type: "error",
      message: error?.response?.data?.message || "Something went wrong!",
    });
  }
};
const updateProfile = async ({ data, dispatch, token, showAlert }) => {
  try {
    const resp = await axiosPublic.post(
      `/api/updateprofile?access_token=${token}`,
      data
    );
    if (resp && resp.status === 200) {
      showAlert({ type: "success", message: resp.data });
      getProfile({ dispatch, token, showAlert });
    }
  } catch (error) {
    showAlert({
      type: "error",
      message: error?.response?.data?.error || "Server error",
    });
  }
};

// get user
const getUser = async ({ dispatch, showAlert, asyncSearchList, email }) => {
  try {
    const resp = await asyncSearchList({
      apiCode: "participant",
      condition: { page: 1, limit: 1, q: { email } },
    });
    if (resp && Array.isArray(resp) && resp.length > 0) {
      dispatch(updateUser(resp[0]));
    }
  } catch (error) {
    showAlert({
      type: "error",
      message: error?.response?.data?.message || "Something went wrong!",
    });
  }
};

// get rights
const getRights = async ({
  dispatch,
  showAlert,
  asyncSearchList,
  group_id,
}) => {
  try {
    const resp = await asyncSearchList({
      apiCode: "right",
      condition: { page: 1, limit: 999999, q: { email: group_id } },
    });
    if (resp && Array.isArray(resp)) {
      dispatch(updateRights(resp));
    }
  } catch (error) {
    showAlert({
      type: "error",
      message: error?.response?.data?.message || "Something went wrong!",
    });
  }
};

export { loginUser, logoutUser, getProfile, updateProfile, getUser, getRights };
