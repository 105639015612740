import React, { useEffect, useId, useRef, useState } from "react";
import { Avatar, Box, IconButton } from "@mui/material";
import { HiPlus } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import { useDropzone } from "react-dropzone";

function ImageInput({ url, onChange }) {
  const inputRef = useRef();
  const [imageUrl, setImageUrl] = useState(url);
  const id = useId();

  const onDrop = (acceptedFiles) => {
    handleFileChange({ target: { files: acceptedFiles } });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const { onClick, onBlur, onFocus, onKeyDown, ...rootProps } = getRootProps();

  const handleDeleteFile = () => {
    onChange(undefined);
    setImageUrl("");
  };

  const handleChooseFile = () => {
    inputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const imageURL = URL.createObjectURL(file);
    onChange(file);
    setImageUrl(imageURL);
  };

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(imageUrl);
    };
  }, [imageUrl]);

  useEffect(() => {
    setImageUrl(url);
  }, [url]);

  return (
    <Box
      sx={{
        paddingTop: "100%",
        width: "100%",
        position: "relative",
        transform: isDragActive ? "scale(1.05)" : "",
      }}
      {...rootProps}
    >
      {imageUrl ? (
        <>
          <Avatar
            src={imageUrl || url}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={handleChooseFile}
          />
          <IconButton
            onClick={handleDeleteFile}
            sx={{
              position: "absolute",
              top: "5px",
              right: "5px",
              backgroundColor: "common.white",
              color: "common.black",
              padding: "5px",
              "&:hover": {
                backgroundColor: "common.white",
              },
            }}
          >
            <MdClose fontSize="14px" />
          </IconButton>
        </>
      ) : (
        <Box
          sx={{
            position: "absolute",
            inset: "0",
            backgroundColor: "#ededed",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton onClick={handleChooseFile}>
            <HiPlus fontSize="20px" />
          </IconButton>
        </Box>
      )}
      <input
        id={id}
        type="file"
        hidden
        {...getInputProps({
          accept: "image/*",
          onChange: handleFileChange,
          multiple: false,
          ref: inputRef,
        })}
      />
    </Box>
  );
}

export default ImageInput;
