const API_URL = "https://api135.goodapp.vn/api";
const PUBLIC_URL = "https://api135.goodapp.vn";
const ID_APP = "66432d1af06bef4e9985aa6a";

const VIEW_RIGHT = "view";
const VIEWOFOTHER_RIGHT = "viewOfOther";
const ADD_RIGHT = "add";
const UPDATE_RIGHT = "update";
const DELETE_RIGHT = "delete";

export {
  API_URL,
  PUBLIC_URL,
  ID_APP,
  VIEW_RIGHT,
  VIEWOFOTHER_RIGHT,
  ADD_RIGHT,
  UPDATE_RIGHT,
  DELETE_RIGHT,
};
