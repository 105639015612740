import { Badge, Box, Container, Stack, Typography } from "@mui/material";
import React from "react";

function Line({ title, titleSx = {}, count, valueComponent }) {
  return (
    <Box sx={{ width: "100vw" }}>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "100%", height: "42px" }}
        >
          <Badge
            color="secondary"
            badgeContent={count}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            sx={{
              "& .MuiBadge-badge": { transform: "translate(140%, 0%)" },
            }}
          >
            <Typography sx={titleSx}>{title}</Typography>
          </Badge>
          {valueComponent}
        </Stack>
      </Container>
    </Box>
  );
}

export default Line;
