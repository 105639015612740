import { updatePttt } from "../reducers/pttt.reducer";

export const getPttt = async ({ asyncSearchList, showAlert, dispatch }) => {
  try {
    const data = await asyncSearchList({
      apiCode: "ptthanhtoan",
      condition: { page: 1, limit: 10000, q: { status: true } },
    });
    dispatch(updatePttt(data));
  } catch (error) {
    showAlert({
      type: "error",
      message: error?.message || "Something went wrong!",
    });
  }
};
