import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import AppBar from "../../../../components/appbar/AppBar";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import SelectApiInput from "../../../../components/input/SelectApiInput";
import { FaUserAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import DateTimeInput from "../../../../components/input/DateTimeInput";
import { useOrderContext } from "../../OrderPage";
import AreaInput from "../../../../components/input/AreaInput";

function OrderInfo({ open, onClose }) {
  const { handleUpdateCurrentHd } = useOrderContext();
  const { currentHd } = useSelector((state) => state.order);
  const [nv, setNv] = useState({
    ma_nv: currentHd?.ma_nv || "",
    ten_nv: currentHd?.ten_nv || "",
  });

  useEffect(() => {
    setNv({
      ma_nv: currentHd?.ma_nv || "",
      ten_nv: currentHd?.ten_nv || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHd?.ma_nv]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{ width: "100vw" }}
      PaperProps={{ sx: { width: "100%" } }}
    >
      <Box sx={{ width: "100%" }}>
        {/* Top start */}
        <AppBar>
          <Stack
            sx={{ width: "100%", height: "100%" }}
            direction="row"
            alignItems="center"
            spacing="10px"
          >
            <IconButton sx={{ color: "common.white" }} onClick={onClose}>
              <MdOutlineArrowBackIosNew size={25} />
            </IconButton>
            <Typography variant="h1" sx={{ color: "common.white" }}>
              Thông Tin Đơn Hàng
            </Typography>
          </Stack>
        </AppBar>
        <Box
          sx={{
            height: "calc(100vh - 60px)",
            overflow: "auto",
            backgroundColor: "background.default",
            padding: "5px 0",
          }}
        >
          <Stack sx={{ backgroundColor: "background.paper" }}>
            <Container>
              <SelectApiInput
                placeholder="Chọn nhân viên"
                searchPlaceholder="Tìm theo tên, mã nhân viên"
                startAdornment={<FaUserAlt size={18} />}
                disabled
                apiCode="dmnv"
                searchFields={["ma_nv", "ten_nv"]}
                getOptionLabel={(option) =>
                  option?.ten_nv ? `${option.ten_nv} (${option.ma_nv})` : ""
                }
                isOptionEqualToValue={(op, val) => op?.ma_nv === val?.ma_nv}
                value={nv}
                onChange={(val) => setNv(val)}
              />
            </Container>
            <Divider />
            <Container>
              <DateTimeInput
                defaultValue={currentHd?.ngay_ct}
                onChange={(val) => {
                  handleUpdateCurrentHd({ ngay_ct: val });
                }}
              />
            </Container>
          </Stack>
          <Box
            sx={{
              marginTop: 2,
              backgroundColor: "common.white",
              height: "auto",
              py: 1,
            }}
          >
            <Container>
              <AreaInput
                minRows={3}
                placeholder="Ghi chú đơn hàng"
                value={currentHd?.dien_giai || ""}
                onChange={(e) => {
                  handleUpdateCurrentHd({ dien_giai: e.target.value });
                }}
              />
            </Container>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}

export default OrderInfo;
