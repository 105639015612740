import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    login: {
      data: null,
      isFetching: false,
      error: false,
    },
    user: null,
    rights: [],
    profile: null,
  },
  reducers: {
    // login
    loginStart(state) {
      state.login.isFetching = true;
    },
    loginFail(state) {
      state.login.isFetching = false;
      state.login.error = true;
      state.login.data = null;
    },
    loginSuccess(state, action) {
      state.login.isFetching = false;
      state.login.error = false;
      state.login.data = action.payload;
    },
    // logout
    logoutSuccess(state) {
      state.login.data = null;
      state.user = null;
      state.profile = null;
      state.rights = [];
    },
    // update data
    updateToken(state, action) {
      state.login.user = { ...state.login.data, ...action.payload };
    },
    editProfile(state, action) {
      state.profile = action.payload;
    },
    // update user
    updateUser(state, action) {
      state.user = action.payload;
    },
    // udpate rights
    updateRights(state, action) {
      state.rights = action.payload;
    },
  },
});

export const {
  loginStart,
  loginFail,
  loginSuccess,
  logoutSuccess,
  updateToken,
  editProfile,
  updateUser,
  updateRights,
} = authSlice.actions;
export default authSlice.reducer;
