import React, { createContext, useState } from "react";
import DialogBase from "../../components/dialog/DialogBase";
import { Button } from "@mui/material";

export const ConfirmContext = createContext();

function ConfirmProvider({ children }) {
  const [confirmOption, setConfirmOption] = useState({
    open: false,
    title: "",
    content: "",
    handleConfirm: null,
  });
  const showConfirm = ({ title, content, onConfirm }) => {
    setConfirmOption({
      open: true,
      title,
      content,
      handleConfirm: onConfirm,
    });
  };
  const handleClose = () => {
    setConfirmOption({
      open: false,
      title: "",
      content: "",
      handleConfirm: null,
    });
  };
  return (
    <ConfirmContext.Provider value={showConfirm}>
      <DialogBase
        open={confirmOption.open}
        onClose={handleClose}
        title={confirmOption.title}
        content={confirmOption.content}
        width="600px"
        actions={[
          <Button
            key={1}
            onClick={() => {
              confirmOption.handleConfirm();
              setConfirmOption({ ...confirmOption, open: false });
            }}
          >
            Xác nhận
          </Button>,
          <Button
            key={2}
            onClick={handleClose}
            sx={{
              color: "error.main",
            }}
          >
            Hủy
          </Button>,
        ]}
      />
      {children}
    </ConfirmContext.Provider>
  );
}

export default ConfirmProvider;
