import React from "react";
import { Stack, TextField, Typography } from "@mui/material";
import { numeralCustom } from "../../utils/helpers";

function NumberInput({
  title,
  titleSx = {},
  textFieldSx = {},
  value,
  onChange,
}) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ height: "42px", padding: "0px", minHeight: "unset" }}
    >
      {title && <Typography sx={{ ...titleSx }}>{title}</Typography>}
      <TextField
        type="text"
        value={numeralCustom(value).format()}
        onChange={(e) => {
          const val = numeralCustom(e.target.value).value();
          onChange({ target: { value: Number(val) } });
        }}
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        sx={{
          width: "200px",
          borderBottom: "2px solid",
          borderColor: "divider",
          "& .MuiInputBase-root": {
            "& .MuiInputBase-input": {
              py: 0,
              px: 1,
              height: "42px",
              textAlign: "right",
            },
            "& fieldset": {
              border: "none",
              outline: "none",
            },
          },
          ...textFieldSx,
        }}
      />
    </Stack>
  );
}

export default NumberInput;
