import numeral from "numeral";
import moment from "moment";
import {
  ADD_RIGHT,
  DELETE_RIGHT,
  UPDATE_RIGHT,
  VIEW_RIGHT,
  VIEWOFOTHER_RIGHT,
} from "./constants";

numeral.register("locale", "vi", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  abbreviations: {
    thousand: "Nghìn",
    million: "Triệu",
    billion: "Tỷ",
    trillion: "Nghìn Tỷ",
  },
  currency: {
    symbol: "₫",
  },
});
numeral.locale("vi");
function formatDateDisplay(date, format = "DD/MM/YYYY") {
  if (!date) return;
  if (!moment(date).isValid()) return date;
  return moment(date).format(format);
}
async function postOrPutDataDanhMuc({
  isEdit,
  asyncPostData,
  asyncPutData,
  data,
  apiCode,
  handleClose,
  reset,
  dataReset = {},
  setLoad,
  withIdApp,
}) {
  const method = isEdit ? asyncPutData : asyncPostData;
  const objMethod = { apiCode, withIdApp, data };
  if (isEdit) {
    objMethod.uniqueValue = data._id;
  }
  const resp = await method(objMethod);
  if (!resp.message) {
    handleClose?.();
    reset?.(dataReset);
    setLoad((prev) => prev + 1);
  }
  return resp;
}

const downloadFile = ({ link, showAlert, successMessage }) => {
  if (!link) return;
  try {
    const aTag = document.createElement("a");
    aTag.href = link;
    aTag.download = true;
    aTag.click();
    showAlert({
      type: "success",
      message: successMessage || "Đã tải xuống file excel mẫu",
    });
  } catch (error) {
    showAlert({
      type: "error",
      message: error?.message || "Something went wrong!",
    });
  }
};

export const typeOf = (obj) =>
  Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();

export const isObject = (obj) => typeOf(obj) === "object";
export const isArray = (obj) => typeOf(obj) === "array";
export const isString = (obj) => typeOf(obj) === "string";
export const isNumber = (obj) => typeOf(obj) === "number";
export const isBoolean = (obj) => typeOf(obj) === "boolean";

// group by
const groupBy = ({ data = [], callbackMatch }) => {
  if (!callbackMatch) return data;
  let result = {};
  data.forEach((item) => {
    if (result[callbackMatch(item)]) {
      result[callbackMatch(item)].push(item);
    } else {
      result[callbackMatch(item)] = [item];
    }
  });
  return Object.values(result);
};
// generate search keywords
const generateSearchKeywords = (obj = {}) => {
  let result = "";
  for (let key in obj) {
    result += `${!result ? "?" : "&"}${key}=${JSON.stringify(obj[key])}`;
  }
  return result;
};

// allow show authorize
const allowShowAuthorize = ({
  rights = [{ module: "dmvt", [VIEW_RIGHT]: true, [VIEWOFOTHER_RIGHT]: true }],
  module = "dmvt", // string || [string]
}) => {
  if (isArray(module)) {
    for (let i = 0; i < module.length; i++) {
      const currentRight = rights.find((r) => r.module === module[i]);
      if (currentRight?.[VIEW_RIGHT] || currentRight?.[VIEWOFOTHER_RIGHT]) {
        return true;
      }
    }
    return false;
  } else if (isString(module)) {
    const existed = rights.find((r) => r.module === module);
    if (existed && (existed[VIEW_RIGHT] || existed[VIEWOFOTHER_RIGHT])) {
      return true;
    }
  }
  return false;
};
// allow add authorize
const allowAddAuthorize = ({
  rights = [{ module: "dmvt", [ADD_RIGHT]: true }],
  module = "dmvt", // string
}) => {
  if (typeof module === "string") {
    const existed = rights.find((r) => r.module === module);
    if (existed && existed[ADD_RIGHT]) {
      return true;
    }
  }
  return false;
};
// allow update authorize
const allowUpdateAuthorize = ({
  rights = [{ module: "dmvt", [UPDATE_RIGHT]: true }],
  module = "dmvt", // string
}) => {
  if (typeof module === "string") {
    const existed = rights.find((r) => r.module === module);
    if (existed && existed[UPDATE_RIGHT]) {
      return true;
    }
  }
  return false;
};
// allow delete authorize
const allowDeleteAuthorize = ({
  rights = [{ module: "dmvt", [DELETE_RIGHT]: true }],
  module = "dmvt",
}) => {
  const existed = rights.find((r) => r.module === module);
  if (existed && existed[DELETE_RIGHT]) {
    return true;
  }
  return false;
};

export {
  numeral as numeralCustom,
  formatDateDisplay,
  postOrPutDataDanhMuc,
  downloadFile,
  groupBy,
  generateSearchKeywords,
  allowShowAuthorize,
  allowUpdateAuthorize,
  allowDeleteAuthorize,
  allowAddAuthorize,
};
