import { createSlice } from "@reduxjs/toolkit";

const storeSlice = createSlice({
  name: "store",
  initialState: {
    data: [],
    currentStore: null,
  },
  reducers: {
    updateStore(state, action) {
      state.data = action.payload;
    },
    initCurrentStore(state, action) {
      const existed = state.data.find(
        (item) => item._id === state.currentStore?._id
      );
      if (existed) {
        state.currentStore = existed;
      } else {
        state.currentStore = action.payload;
      }
    },
    updateCurrentStore(state, action) {
      state.currentStore = action.payload;
    },
  },
});

export const { updateStore, initCurrentStore, updateCurrentStore } =
  storeSlice.actions;
export default storeSlice.reducer;
