import { Avatar, Button, Stack, Typography } from "@mui/material";
import ErrorIcon from "../../assets/images/error.png";
import InfoIcon from "../../assets/images/info.png";
import WarningIcon from "../../assets/images/warning.png";
import SuccessIcon from "../../assets/images/success.png";
import DialogBase from "../../components/dialog/DialogBase";
import { createContext, useState } from "react";

export const AlertContext = createContext();

function AlertProvider({ children }) {
  const [alertOptions, setAlertOptions] = useState({
    open: false,
    type: "error",
    message: "Something went wrong!",
  });

  const showAlert = ({ type = "error", message = "Something went wrong!" }) => {
    setAlertOptions({ open: true, type, message });
  };

  const handleClose = () => {
    setAlertOptions({
      ...alertOptions,
      open: false,
    });
  };

  const renderIconByType = (type) => {
    switch (type) {
      case "success":
        return (
          <Avatar src={SuccessIcon} sx={{ width: "60px", height: "60px" }} />
        );
      case "info":
        return <Avatar src={InfoIcon} sx={{ width: "60px", height: "60px" }} />;
      case "warning":
        return (
          <Avatar src={WarningIcon} sx={{ width: "60px", height: "60px" }} />
        );
      default:
        return (
          <Avatar src={ErrorIcon} sx={{ width: "60px", height: "60px" }} />
        );
    }
  };

  return (
    <AlertContext.Provider value={showAlert}>
      <DialogBase
        open={alertOptions.open}
        onClose={handleClose}
        title="Thông báo"
        content={
          <Stack
            // sx={{ width: "80vw", maxWidth: "400px" }}
            spacing="10px"
            alignItems="center"
          >
            {renderIconByType(alertOptions.type)}
            <Typography sx={{ textAlign: "center" }}>
              {alertOptions.message}
            </Typography>
          </Stack>
        }
        actions={[
          <Button
            key="0"
            onClick={handleClose}
            variant="contained"
            sx={{ color: "common.white", textTransform: "none" }}
          >
            Đã hiểu
          </Button>,
        ]}
      />

      {children}
    </AlertContext.Provider>
  );
}
export default AlertProvider;
