import React, { useState } from "react";
import {
  Box,
  Collapse,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import DetailInfo from "./DetailInfo";
import Quantity from "../../number/Quantity";
import { useSwipeable } from "react-swipeable";
import { BsTrash } from "react-icons/bs";
import { numeralCustom } from "../../../../../utils/helpers";
import { cloneDeep } from "lodash";
import { useSelector } from "react-redux";
import { useOrderContext } from "../../../OrderPage";

function Detail({ data }) {
  const { handleUpdateCurrentHd } = useOrderContext();
  const { currentHd } = useSelector((state) => state.order);
  const [openInfo, setOpenInfo] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleNumberChange = (number) => {
    const currentHdClone = cloneDeep(currentHd);
    const detail = (currentHdClone?.details || []).find(
      (d) => d._id === data._id
    );
    detail.sl_xuat = number;
    detail.tien = (detail.sl_xuat || 0) * (detail.gia_ban || 0);
    detail.tien_xuat = detail.tien || 0 - detail.tien_ck || 0;
    handleUpdateCurrentHd({ details: currentHdClone.details || [] });
  };

  const handleSwipedLeft = () => {
    setOpenDelete(true);
  };

  const handleSwipedRight = () => {
    setOpenDelete(false);
  };

  const swipeHandler = useSwipeable({
    onSwipedLeft: handleSwipedLeft,
    onSwipedRight: handleSwipedRight,
  });

  const handleCloseInfo = () => setOpenInfo(false);

  const handleDeleteDetail = () => {
    const currentHdClone = cloneDeep(currentHd);
    const newDetails = (currentHdClone?.details || []).filter(
      (detail) => detail._id !== data._id
    );
    handleUpdateCurrentHd({ details: newDetails });
  };

  return (
    <>
      <DetailInfo
        open={openInfo}
        onClose={handleCloseInfo}
        data={data}
        handleDeleteDetail={handleDeleteDetail}
      />
      <Box
        onClick={() => setOpenInfo(true)}
        sx={{
          width: "100%",
          flexShrink: 0,
          backgroundColor: "background.paper",
          px: 0,
          py: 1,
          border: "1px solid",
          borderColor: "divider",
          borderWidth: "1px 0px 1px 0px",
          position: "relative",
        }}
        {...swipeHandler}
      >
        <Collapse in={openDelete} orientation="horizontal">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={{
              position: "absolute",
              inset: 0,
              zIndex: 5,
              backgroundColor: "#00000066",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setOpenDelete(false);
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteDetail();
              }}
              sx={{
                position: "absolute",
                zIndex: 2,
                top: 0,
                right: 0,
                width: "15%",
                height: "100%",
                borderRadius: "0px",
                backgroundColor: "error.main",
                color: "common.white",
                "&:hover": {
                  backgroundColor: "error.main",
                },
              }}
            >
              <BsTrash size={25} />
            </IconButton>
          </Stack>
        </Collapse>
        <Container>
          <Typography>
            <Typography component="span">{data.ten_vt}</Typography>{" "}
            <Typography
              component="span"
              sx={{ color: "text.secondary", fontSize: "13px" }}
            >
              ({data.ma_dvt})
            </Typography>
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography>
              <Typography component="span" sx={{ fontWeight: 500 }}>
                {numeralCustom(data.gia_ban).format()}
              </Typography>{" "}
              <Typography
                component="span"
                sx={{ color: "secondary.main", fontWeight: 600 }}
              >
                X
              </Typography>
            </Typography>
            <Quantity
              disabledMinus={data?.sl_xuat <= 1}
              defaultValue={data?.sl_xuat || 1}
              warning={data.sl_xuat > (data.ton_kho || 0)}
              onChange={handleNumberChange}
            />
          </Stack>
        </Container>
      </Box>
    </>
  );
}

export default Detail;
