import React, { useEffect, useState } from "react";
import { Button, Stack, TextField } from "@mui/material";
import SearchApiDrawer from "../drawer/SearchApiDrawer";
import WrapperInput from "./WrapperInput";

function SelectApiInput({
  sx,
  startAdornment,
  endAdornment,
  placeholder,
  searchPlaceholder,
  fullWidth = true,
  InputProps = {},
  apiCode = "dmvt",
  condition = {},
  searchFields = [],
  withIdApp = true,
  getOptionLabel,
  value,
  onChange,
  renderOption,
  filterOptions,
  isOptionEqualToValue,
  selectedValue,
  closeOnSelect = true,
  reselectToClear = true,
  readOnly,
  disabled,
  FormAdd,
  label,
  id,
  labelWidth = "30%",
  required,
  errorMessage,
  labelSx = {},
  count,
  variant = "standard",
  ...props
}) {
  const [openSearch, setOpenSearch] = useState(false);
  const [selftValue, setSelfValue] = useState(value || selectedValue);
  const [openFormAdd, setOpenFormAdd] = useState(false);

  const handleCloseFormAdd = () => setOpenFormAdd(false);

  useEffect(() => {
    setSelfValue(value);
  }, [value]);

  return (
    <>
      {openFormAdd && (
        <FormAdd open={openFormAdd} onClose={handleCloseFormAdd} />
      )}
      {!openFormAdd && (
        <SearchApiDrawer
          open={openSearch}
          onClose={() => {
            setOpenSearch(false);
          }}
          reselectToClear={reselectToClear}
          searchPlaceholder={searchPlaceholder}
          apiCode={apiCode}
          condition={condition}
          searchFields={searchFields}
          withIdApp={withIdApp}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          filterOptions={filterOptions}
          isOptionEqualToValue={isOptionEqualToValue}
          onChange={onChange}
          selectedValue={selftValue}
          closeOnSelect={closeOnSelect}
          renderBottomList={
            !!FormAdd
              ? () => {
                  return (
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ width: "100vw" }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => setOpenFormAdd(true)}
                        fullWidth
                        sx={{
                          height: "45px",
                          borderRadius: "0px",
                          color: "common.white",
                        }}
                      >
                        Thêm mới
                      </Button>
                    </Stack>
                  );
                }
              : undefined
          }
          fixedScrollHeight={
            !!FormAdd
              ? "calc(100vh - 50px - 1px - 45px)"
              : "calc(100vh - 50px - 1px )"
          }
        />
      )}
      <WrapperInput
        id={id}
        label={label}
        labelWidth={labelWidth}
        required={required}
        errorMessage={errorMessage}
        labelSx={labelSx}
        count={count}
        {...props}
      >
        <TextField
          fullWidth={fullWidth}
          variant={variant}
          placeholder={placeholder}
          disabled={disabled}
          value={!!selftValue ? getOptionLabel?.(selftValue) : ""}
          InputProps={{
            readOnly: true,
            autoComplete: "off",
            startAdornment: startAdornment ? (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ mr: 2, height: "42px", color: "text.secondary" }}
              >
                {startAdornment}
              </Stack>
            ) : null,
            endAdornment,
            ...InputProps,
          }}
          sx={{
            "& .MuiInputBase-root": {
              paddingLeft: 0,
              "& fieldset": {
                border: "none",
                outline: "none",
              },
            },
            "& .MuiFormLabel-root": {
              fontSize: "12px",
              fontWeight: 600,
              color: "primary.main",
              paddingRight: "5px",
              transform: "translate(14px, -12px)",
              backgroundColor: "whitish.pureWhite",
            },
            "& .MuiInputBase-input": {
              height: "42px",
            },
          }}
          // sx={{
          //   "& .MuiInputBase-root": {
          //     paddingLeft: 0,
          //     "& .MuiInputBase-input": { height: "42px" },
          //     "& fieldset": { border: "none" },
          //   },
          //   ...sx,
          // }}
          onClick={readOnly || disabled ? undefined : () => setOpenSearch(true)}
          {...props}
        />
      </WrapperInput>
    </>
  );
}

export default SelectApiInput;
