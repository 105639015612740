import {
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AppBar from "../../../../components/appbar/AppBar";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import Line from "./Line";
import { useSelector } from "react-redux";
import { numeralCustom } from "../../../../utils/helpers";
import ChangeModeInput from "../../../../components/input/ChangeModeInput";
import { useOrderContext } from "../../OrderPage";
import { cloneDeep } from "lodash";
import Discount from "../discount/Discount";
import NumberInput from "../../../../components/input/NumberInput";

function Review({ open, onClose }) {
  const { currentHd } = useSelector((state) => state.order);
  const { data: pttts } = useSelector((state) => state.pttt);
  const { handleUpdateCurrentHd, saveHd } = useOrderContext();
  const [originHd, setOriginHd] = useState();

  const handleSave = async () => {
    await saveHd();
  };

  const handleChietKhauChange = ({ ty_le_ck, tien_ck }) => {
    const currentHdClone = cloneDeep(currentHd);
    currentHdClone.tien_ck_hd = tien_ck || 0;
    currentHdClone.ty_le_ck_hd = ty_le_ck || 0;
    handleUpdateCurrentHd(currentHdClone);
  };

  const handleClose = () => {
    handleUpdateCurrentHd(originHd);
    onClose();
  };

  useEffect(() => {
    if (open) {
      setOriginHd(currentHd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Drawer
      title="Đặt hàng"
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "100vw",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          backgroundColor: "background.default",
        }}
      >
        <AppBar>
          <Stack
            sx={{ width: "100%", height: "100%" }}
            direction="row"
            alignItems="center"
            spacing="10px"
          >
            <IconButton sx={{ color: "common.white" }} onClick={handleClose}>
              <MdOutlineArrowBackIosNew size={25} />
            </IconButton>
            <Typography variant="h1" sx={{ color: "common.white" }}>
              Đặt hàng
            </Typography>
          </Stack>
        </AppBar>
        <Box
          sx={{
            width: "100vw",
            height: "calc(100vh - 60px - 45px)",
            overflow: "auto",
          }}
        >
          <Box sx={{ my: 1, backgroundColor: "background.paper" }}>
            <Line
              title="Tiền hàng"
              titleSx={{ fontWeight: 500 }}
              count={currentHd?.details?.length}
              valueComponent={
                <Typography sx={{ color: "text.secondary" }}>
                  {numeralCustom(currentHd?.t_tien).format()}
                </Typography>
              }
            />
            <Divider />
            <Line
              title="Chiết khấu sản phẩm"
              titleSx={{ fontWeight: 500 }}
              valueComponent={
                <Typography sx={{ color: "text.secondary" }}>
                  {numeralCustom(currentHd?.t_ck).format()}
                </Typography>
              }
            />
            <Divider />
            <Line
              title="Chiết khấu hóa đơn"
              titleSx={{ fontWeight: 500 }}
              valueComponent={
                <ChangeModeInput
                  readOnly={currentHd?.exfields?.dmchietkhau?.length > 0}
                  defaultPercent={currentHd?.ty_le_ck_hd || 0}
                  defaultTien={currentHd?.tien_ck_hd || 0}
                  tienGoc={currentHd?.t_tien || 0}
                  onChietKhauChange={handleChietKhauChange}
                />
              }
            />
            <Box>
              <Discount />
            </Box>
            <Line
              title="Khách cần trả"
              titleSx={{ fontWeight: 500 }}
              valueComponent={
                <Typography>
                  {numeralCustom(currentHd?.t_tt).format()}
                </Typography>
              }
            />
            <Divider />
            <Box>
              <Container>
                <NumberInput
                  title="Khách đã trả"
                  titleSx={{ fontWeight: 500 }}
                  value={currentHd?.exfields?.payed || 0}
                  onChange={(e) => {
                    handleUpdateCurrentHd({
                      exfields: {
                        ...(currentHd?.exfields || {}),
                        payed: e.target.value,
                      },
                    });
                  }}
                />
              </Container>
            </Box>
            <Divider />
            <Box>
              <Container>
                <RadioGroup defaultValue={currentHd?.pt_thanh_toan || ""} row>
                  {pttts &&
                    pttts.length > 0 &&
                    pttts.map((pttt) => {
                      return (
                        <FormControlLabel
                          key={pttt._id}
                          value={pttt._id}
                          control={<Radio />}
                          label={pttt.ten}
                        />
                      );
                    })}
                </RadioGroup>
              </Container>
            </Box>
          </Box>
        </Box>
        <Button
          fullWidth
          variant="contained"
          onClick={handleSave}
          sx={{ height: "45px", color: "common.white", borderRadius: "0px" }}
        >
          Hoàn tất
        </Button>
      </Box>
    </Drawer>
  );
}

export default Review;
