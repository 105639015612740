import React, { createContext, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";

export const BackdropContext = createContext();

function BackdropProvider({ children }) {
  const [open, setOpen] = useState(false);
  const handleClose = () => {};
  return (
    <BackdropContext.Provider value={[open, setOpen]}>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + theme.zIndex.modal,
        }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      {children}
    </BackdropContext.Provider>
  );
}

export default BackdropProvider;
