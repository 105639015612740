import React from "react";
import { Box, Container } from "@mui/material";
import SelectApiInput from "../../../../components/input/SelectApiInput";
import { FaUserTag } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { updateCustomer } from "../../../../redux/reducers/order.reducer";
import { useOrderContext } from "../../OrderPage";
import FormCustomer from "../../../../components/form/FormCustomer";

function Customer() {
  const { handleUpdateCurrentHd } = useOrderContext();
  const { customer } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  const handleCustomerChange = (cust) => {
    dispatch(updateCustomer(cust));
    handleUpdateCurrentHd({ ma_kh: cust?.ma_kh, ten_kh: cust?.ten_kh });
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "42px",
        backgroundColor: "background.paper",
      }}
    >
      <Container sx={{ width: "100%", height: "100%" }}>
        <SelectApiInput
          variant="outlined"
          placeholder="Chọn khách hàng"
          searchPlaceholder="Tìm theo mã, tên khách hàng"
          startAdornment={<FaUserTag size={18} />}
          apiCode="customer"
          searchFields={["ma_kh", "ten_kh"]}
          condition={{ kh_yn: true, ncc_yn: { $ne: true } }}
          getOptionLabel={(option) =>
            option?.ten_kh ? `${option.ten_kh} (${option.ma_kh})` : ""
          }
          isOptionEqualToValue={(op, val) => op?.ma_kh === val?.ma_kh}
          value={customer}
          onChange={handleCustomerChange}
          FormAdd={FormCustomer}
        />
      </Container>
    </Box>
  );
}

export default Customer;
