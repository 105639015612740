import {
  Badge,
  Box,
  Button,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { numeralCustom } from "../../../../utils/helpers";
import { useSelector } from "react-redux";
import Review from "../review/Review";

function Bottom() {
  const { currentHd } = useSelector((state) => state.order);
  const [openReview, setOpenReview] = useState(false);

  const handleCloseReview = () => setOpenReview(false);

  return (
    <>
      <Review open={openReview} onClose={handleCloseReview} />
      <Stack>
        <Box
          sx={{
            width: "100%",
            height: "42px",
            backgroundColor: "primary.100",
          }}
        >
          <Container sx={{ width: "100%", height: "100%" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: "100%", height: "100%" }}
            >
              <Badge
                color="secondary"
                badgeContent={(currentHd?.details || []).length}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                sx={{
                  "& .MuiBadge-badge": { transform: "translate(140%, 0%)" },
                }}
              >
                <Typography>Tiền hàng</Typography>
              </Badge>
              <Typography sx={{ fontWeight: 500 }}>
                {numeralCustom(currentHd?.t_tien || 0).format()} đ
              </Typography>
            </Stack>
          </Container>
        </Box>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={() => setOpenReview(true)}
          sx={{ height: "45px", color: "common.white", borderRadius: "0px" }}
        >
          Đặt Hàng
        </Button>
      </Stack>
    </>
  );
}

export default Bottom;
