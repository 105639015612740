import {
  Avatar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import AppBar from "../../../../components/appbar/AppBar";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { cloneDeep, isArray } from "lodash";
import { useSelector } from "react-redux";
import { useOrderContext } from "../../OrderPage";
import DialogBase from "../../../../components/dialog/DialogBase";
import { formatDateDisplay, numeralCustom } from "../../../../utils/helpers";
import { BsInfoCircle } from "react-icons/bs";
import DiscountIcon from "../../../../assets/images/discount.svg";

function ListDiscount({ onClose, open, discounts = [] }) {
  const { currentHd } = useSelector((state) => state.order);

  return (
    <Drawer
      open={open}
      anchor="right"
      PaperProps={{ sx: { width: "100vw", height: "100vh" } }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "background.default",
        }}
      >
        <AppBar>
          <Stack
            sx={{ width: "100%", height: "100%" }}
            direction="row"
            alignItems="center"
            spacing="10px"
          >
            <IconButton sx={{ color: "common.white" }} onClick={onClose}>
              <MdOutlineArrowBackIosNew size={25} />
            </IconButton>
            <Typography variant="h1" sx={{ color: "common.white" }}>
              Chương trình giảm giá
            </Typography>
          </Stack>
        </AppBar>
        <Box
          sx={{ width: "100%", height: "calc(100vh - 60px)", overflow: "auto" }}
        >
          <Stack spacing="5px" sx={{ py: 1 }}>
            {isArray(discounts) &&
              discounts.length > 0 &&
              discounts.map((discount) => {
                const { gt_hd_tu, gt_hd_den } = discount;
                let active = true;
                if (!!gt_hd_tu && !!gt_hd_den) {
                  active =
                    Number(gt_hd_tu) <= (currentHd?.t_tien || 0) &&
                    Number(gt_hd_den) >= (currentHd?.t_tien || 0);
                } else if (!!gt_hd_tu) {
                  active = Number(gt_hd_tu) <= (currentHd?.t_tien || 0);
                } else if (gt_hd_den) {
                  active = Number(gt_hd_den) >= (currentHd?.t_tien || 0);
                }
                return (
                  <Container key={discount._id}>
                    <DiscountItem discount={discount} active={active} />
                  </Container>
                );
              })}
          </Stack>
        </Box>
      </Box>
    </Drawer>
  );
}

export default ListDiscount;

function DiscountItem({ discount, active }) {
  const { currentHd } = useSelector((state) => state.order);
  const { handleUpdateCurrentHd } = useOrderContext();
  const [openDetail, setOpenDetail] = useState(false);

  const handleCloseDetail = () => setOpenDetail(false);

  const handleSelectDiscount = () => {
    const hdClone = cloneDeep(currentHd || {});
    const existed = (hdClone.exfields?.dmchietkhau || []).find(
      (ck) => ck._id === discount._id
    );
    if (existed) {
      hdClone.exfields.dmchietkhau = hdClone.exfields.dmchietkhau.filter(
        (ck) => ck._id !== discount._id
      );
    } else {
      if (!hdClone.exfields?.dmchietkhau) {
        hdClone.exfields.dmchietkhau = [];
      }
      hdClone.exfields.dmchietkhau.push(discount);
    }
    handleUpdateCurrentHd({ exfields: hdClone.exfields });
  };
  const isSelected = useMemo(() => {
    return (currentHd?.exfields?.dmchietkhau || []).some(
      (ck) => ck._id === discount._id
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHd?.exfields?.dmchietkhau]);

  return (
    <>
      <DialogBase
        title="Điều kiện áp dụng"
        open={openDetail}
        onClose={handleCloseDetail}
        content={
          <Box
            sx={{
              maxHeight: "calc(90vh - 20px - 39px - 39px)",
              overflow: "auto",
              paddingRight: "4px",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                border: "1px solid #ddd",
                borderRadius: "6px 6px 0 0",
              }}
            >
              <Typography
                sx={{
                  width: "40%",
                  textAlign: "center",
                  fontWeight: 600,
                  borderRight: "1px solid #ddd",
                  padding: "8px 0",
                }}
              >
                Chi nhánh
              </Typography>
              <Typography
                sx={{
                  width: "60%",
                  textAlign: "center",
                  padding: "8px 0",
                }}
              >
                {discount.ten_kho}
              </Typography>
            </Stack>
            {(discount.ma_kh || discount.ten_kh) && (
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  border: "1px solid #ddd",
                  borderTop: "none",
                }}
              >
                <Typography
                  sx={{
                    width: "40%",
                    textAlign: "center",
                    fontWeight: 600,
                    borderRight: "1px solid #ddd",
                    padding: "8px 0",
                  }}
                >
                  Khách hàng
                </Typography>
                <Typography
                  sx={{
                    width: "60%",
                    textAlign: "center",
                    padding: "8px 0",
                  }}
                >
                  {discount.ten_kh || discount.ma_kh}
                </Typography>
              </Stack>
            )}
            {(discount.nh_kh || discount.ten_nh_kh) && (
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  border: "1px solid #ddd",
                  borderTop: "none",
                }}
              >
                <Typography
                  sx={{
                    width: "40%",
                    textAlign: "center",
                    fontWeight: 600,
                    borderRight: "1px solid #ddd",
                    padding: "8px 0",
                  }}
                >
                  Nhóm khách
                </Typography>
                <Typography
                  sx={{
                    width: "60%",
                    textAlign: "center",
                    padding: "8px 0",
                  }}
                >
                  {discount.ten_nh_kh}
                </Typography>
              </Stack>
            )}
            {(Number(discount.gt_hd_tu || 0) > 0 ||
              Number(discount.gt_hd_den || 0) > 0) && (
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  border: "1px solid #ddd",
                  borderTop: "none",
                }}
              >
                <Typography
                  sx={{
                    width: "40%",
                    textAlign: "center",
                    fontWeight: 600,
                    borderRight: "1px solid #ddd",
                    padding: "8px 0",
                  }}
                >
                  Giá trị hóa đơn
                </Typography>
                <Typography
                  sx={{
                    width: "60%",
                    textAlign: "center",
                    padding: "8px 0",
                    fontWeight: 600,
                  }}
                >
                  <Typography component="span">Từ</Typography>{" "}
                  <Typography
                    component="span"
                    sx={{ color: "error.main", fontWeight: 600 }}
                  >
                    {numeralCustom(discount.gt_hd_tu).format()}đ
                  </Typography>{" "}
                  {Number(discount.gt_hd_den) > 0 && (
                    <>
                      <Typography component="span">Đến</Typography>{" "}
                      <Typography
                        component="span"
                        sx={{ color: "error.main", fontWeight: 600 }}
                      >
                        {numeralCustom(discount.gt_hd_den).format()}đ
                      </Typography>
                    </>
                  )}
                </Typography>
              </Stack>
            )}
          </Box>
        }
      />
      <Box
        sx={{
          borderRadius: "6px",
          border: "1px dashed",
          borderColor: "#ddd",
          backgroundColor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="flex-start"
          sx={{
            padding: "10px",
            borderBottom: "1px dashed",
            borderColor: "#ddd",
          }}
        >
          <Stack alignItems="flex-start" sx={{ flex: 1 }}>
            {!discount.ty_le_ck ? (
              <Typography
                sx={{
                  textTransform: "uppercase",
                  color: "error.main",
                  fontSize: "16px",
                  fontWeight: 600,
                  textWrap: true,
                }}
              >
                Giảm {numeralCustom(discount.tien_ck).format()}đ
              </Typography>
            ) : (
              <Typography
                sx={{
                  textTransform: "uppercase",
                  color: "error.main",
                  fontSize: "16px",
                  fontWeight: 600,
                  textWrap: true,
                }}
              >
                Giảm {discount.ty_le_ck}%{" "}
                {discount.tien_ck
                  ? `, tối đa ${numeralCustom(discount.tien_ck).format()}đ`
                  : null}
              </Typography>
            )}
            <Typography
              sx={{
                textWrap: true,
              }}
            >
              {discount.ten_chietkhau}
            </Typography>
            <Typography>
              <Typography component="span" sx={{ fontWeight: 600 }}>
                Hạn dùng:
              </Typography>{" "}
              <Typography component="span">
                {formatDateDisplay(discount.hieu_luc_den, "DD/MM/YYYY HH:mm")}
              </Typography>
            </Typography>
          </Stack>
          <Avatar
            sx={{ width: "60px", height: "60px" }}
            src={DiscountIcon}
            alt="discount-image"
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            padding: "10px",
          }}
        >
          <Button
            onClick={() => setOpenDetail(true)}
            startIcon={<BsInfoCircle size={20} />}
          >
            Điều kiện
          </Button>
          <Button
            onClick={handleSelectDiscount}
            disabled={!active}
            variant="contained"
            sx={{
              color: "common.white",
              boxShadow: "none",
              backgroundColor: isSelected ? "error.main" : "primary.main",
              "&:hover": {
                boxShadow: "none",
                backgroundColor: isSelected ? "error.main" : "primary.main",
              },
            }}
          >
            {isSelected ? "Bỏ chọn" : "Áp dụng"}
          </Button>
        </Stack>
      </Box>
    </>
  );
}
