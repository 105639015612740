import React, { useEffect, useState } from "react";
import { usePtContext } from "../../Ptpage";
import SearchApiDrawer from "../../../../components/drawer/SearchApiDrawer";
import { Button, Stack } from "@mui/material";
import FormCustomer from "../../../../components/form/FormCustomer";

function ForceCustomer() {
  const { phieuThu, handleUpdatePhieuThu } = usePtContext();
  const [open, setOpen] = useState(!phieuThu?.ma_kh);
  const [openFormAdd, setOpenFormAdd] = useState(false);

  const handleCloseFormAdd = () => {
    setOpenFormAdd(false);
  };

  const handleSelectCustomer = (cust) => {
    handleUpdatePhieuThu({
      ma_kh: cust?.ma_kh || "",
      ten_kh: cust?.ten_kh || "",
    });
  };

  useEffect(() => {
    if (!!phieuThu?.ma_kh) {
      setOpen(false);
    }
  }, [phieuThu?.ma_kh]);

  return (
    <>
      {openFormAdd && (
        <FormCustomer open={openFormAdd} onClose={handleCloseFormAdd} />
      )}
      {!openFormAdd && (
        <SearchApiDrawer
          open={open}
          getOptionLabel={(option) =>
            option.ten_kh ? `${option.ten_kh} (${option.ma_kh})` : ""
          }
          apiCode="customer"
          searchFields={["ma_kh", "ten_kh", "dien_thoai"]}
          condition={{ kh_yn: true, ncc_yn: { $ne: true } }}
          searchPlaceholder="Tìm theo mã, tên, điện thoại khách hàng"
          onChange={handleSelectCustomer}
          renderBottomList={() => {
            return (
              <Stack
                direction="row"
                alignItems="center"
                sx={{ width: "100vw" }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => setOpenFormAdd(true)}
                  sx={{
                    height: "45px",
                    borderRadius: "0px",
                    color: "common.white",
                  }}
                >
                  Thêm mới
                </Button>
              </Stack>
            );
          }}
          fixedScrollHeight="calc(100vh - 50px - 1px - 45px)"
        />
      )}
    </>
  );
}

export default ForceCustomer;
