import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Collapse,
  Container,
  Divider,
  FormControlLabel,
  IconButton,
  ListItemButton,
  ListItemIcon,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { CiSearch } from "react-icons/ci";
import SearchApiDrawer from "../../../../components/drawer/SearchApiDrawer";
import useLinkImage from "../../../../hooks/useLinkImage";
import ProductImage from "../../../../assets/images/product.png";
import { numeralCustom } from "../../../../utils/helpers";
import { IoFilter } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectMulti } from "../../../../redux/reducers/order.reducer";
import DrawerFilter from "./DrawerFilter";
// import SelectLo from "../select-lo/SelectLo";
import useBackdropContext from "../../../../hooks/hookContext/useBackdropContext";
import moment from "moment";
import useApisContext from "../../../../hooks/hookContext/useApisContext";
import { cloneDeep, isArray } from "lodash";
import useAlertContext from "../../../../hooks/hookContext/useAlertContext";
import { v4 } from "uuid";
import { useOrderContext } from "../../OrderPage";
import Quantity from "../number/Quantity";

const emptyValue = [0, "", null];

function SearchProduct() {
  const generateLinkImage = useLinkImage();
  const { selectMulti, customer, currentHd } = useSelector(
    (state) => state.order
  );
  const { currentStore } = useSelector((state) => state.store);
  const [, setBackdrop] = useBackdropContext();
  const { asyncSearchList } = useApisContext();
  const showAlert = useAlertContext();
  const { handleUpdateCurrentHd } = useOrderContext();
  const [openSearch, setOpenSearch] = useState(false);
  // const [tempDetail, setTempDetail] = useState();
  const [openFilter, setOpenFilter] = useState(false);
  // const [openLo, setOpenLo] = useState(false);
  const [filter, setFilter] = useState({ group: null });
  const [condition, setCondition] = useState();
  const dispatch = useDispatch();

  const details = currentHd?.details || [];

  const handleCloseSearch = () => {
    setOpenSearch(false);
  };

  const handleCloseFilter = () => setOpenFilter(false);

  const handleReset = () => {
    handleUpdateCurrentHd({ details: [] });
  };

  // const handleCloseLo = () => setOpenLo(false);

  const handleQuantityChange = (detail, val) => {
    if (!detail) return;
    const currentHdClone = cloneDeep(currentHd);
    const currentDetail = (currentHdClone?.details || []).find(
      (d) => d._id === detail?._id
    );
    if (val <= 0) {
      currentHdClone.details = currentHd.details.filter(
        (item) => item._id !== detail._id
      );
    } else {
      currentDetail.sl_xuat = val;
      currentDetail.tien =
        (currentDetail.sl_xuat || 0) * (currentDetail.gia_ban || 0);
      currentDetail.tien_xuat =
        (currentDetail.tien || 0) - (currentDetail.tien_ck || 0);
    }
    handleUpdateCurrentHd({ details: currentHdClone?.details || [] });
  };

  // const getLo = async (ma_vt) => {
  //   const dateCompare = moment().hours(0).minutes(0).seconds(0).milliseconds(0);
  //   const respLo = await asyncSearchList({
  //     apiCode: "dmlo",
  //     condition: {
  //       page: 1,
  //       limit: 99999,
  //       q: {
  //         status: true,
  //         ma_vt,
  //         han_sd: { $gt: dateCompare },
  //       },
  //       sort: { han_sd: 1 },
  //     },
  //   });
  //   return respLo[0];
  // };

  const handleSelectProduct = async (selectedProduct) => {
    try {
      if (!selectedProduct) return;
      setBackdrop(true);
      const compareDate = moment(currentHd?.ngay_ct)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0);
      const resp = await asyncSearchList({
        apiCode: "dmgiaban",
        condition: {
          page: 1,
          limit: 99999,
          q: {
            $and: [
              { status: true },
              {
                $or: [
                  { ma_kho: currentStore?.ma_kho || "" },
                  { ma_kho: { $in: emptyValue } },
                ],
              },
              { ma_vt: selectedProduct?.ma_vt },
              {
                hieu_luc_tu: { $lte: compareDate },
                hieu_luc_den: { $gte: compareDate },
              },
              {
                $or: [
                  {
                    ma_kh: customer?.ma_kh || "",
                    nh_kh: customer?.nh_kh || "",
                  },
                  { ma_kh: { $in: emptyValue }, nh_kh: { $in: emptyValue } },
                  { ma_kh: customer?.ma_kh || "" },
                  { nh_kh: customer?.nh_kh || "" },
                ],
              },
            ],
          },
        },
      });
      if (resp && isArray(resp) && resp.length > 0) {
        selectedProduct.exfields = { dmgiaban: resp };
      }
      const { ma_vt, ten_vt, gia_ban_le, gia_mua, ma_dvt, ton00, exfields } =
        selectedProduct;
      const detailData = {
        _id: v4(),
        dien_giai: "",
        gia_ban: gia_ban_le || 0,
        gia_ban_nt: gia_ban_le || 0,
        gia_von: gia_mua || 0,
        gia_von_nt: gia_mua || 0,
        ma_dvt,
        ma_kho: currentStore?.ma_kho || "",
        ma_lo: "",
        ma_vt,
        sl_xuat: 1,
        ten_kho: currentStore?.ten_kho || "",
        ten_vt,
        tien: gia_ban_le || 0,
        tien_ck: 0,
        tien_ck_nt: 0,
        tien_thue: 0,
        tien_thue_nt: 0,
        tien_xuat: gia_ban_le || 0,
        tien_xuat_nt: gia_ban_le || 0,
        tk_ck: "1131",
        tk_dt: "1131",
        tk_gv: "1131",
        tk_vt: "1561",
        ty_le_ck: 0,
        ton_kho: ton00 || 0,
        ds_tem: exfields?.dmgiaban ? [JSON.stringify(exfields.dmgiaban)] : [],
      };
      const currentHdClone = cloneDeep(currentHd);
      currentHdClone.details.push(detailData);
      handleUpdateCurrentHd({ details: currentHdClone.details });
      if (!selectMulti) {
        handleCloseSearch();
      }
      // if (selectedProduct.ma_lo_yn) {
      //   detailData.sl_los = [];
      //   const lo = await getLo(selectedProduct.ma_vt);
      //   if (lo) {
      //     detailData.sl_los.push({
      //       ma_lo: lo.ma_lo,
      //       sl_xuat: 1,
      //     });
      //   }
      //   setTempDetail(detailData);
      //   setOpenLo(true);
      // } else {
      //   const currentHdClone = cloneDeep(currentHd);
      //   currentHdClone.details.push(detailData);
      //   handleUpdateCurrentHd({ details: currentHdClone.details });
      //   if (!selectMulti) {
      //     handleCloseSearch();
      //   }
      // }
    } catch (error) {
      showAlert({
        type: "error",
        message: error?.message || "Something went wrong!",
      });
    } finally {
      setBackdrop(false);
    }
  };

  useEffect(() => {
    let result = {};
    if (filter.group) {
      result.ma_nvt = filter.group.value;
    }
    setCondition(result);
  }, [filter]);

  return (
    <>
      {/* <SelectLo
        open={openLo}
        onClose={handleCloseLo}
        handleCloseSearch={handleCloseSearch}
        detail={tempDetail}
      /> */}
      <DrawerFilter
        open={openFilter}
        onClose={handleCloseFilter}
        filter={filter}
        setFilter={setFilter}
      />
      <SearchApiDrawer
        open={openSearch}
        onClose={handleCloseSearch}
        searchPlaceholder="Tìm theo mã, tên hàng hóa"
        apiCode="dmvt"
        condition={condition}
        searchFields={["ma_vt", "ten_vt"]}
        getOptionLabel={(option) =>
          option.ten_vt ? `${option.ten_vt} (${option.ma_vt})` : ""
        }
        renderOption={(option, { key, onClick }) => {
          const linkImage =
            option.picture || option.picture2 || option.picture3;
          const detail = (details || []).find((d) => d.ma_vt === option.ma_vt);
          return (
            <React.Fragment key={key}>
              <Box sx={{ backgroundColor: detail ? "primary.100" : "" }}>
                <ListItemButton
                  onClick={() =>
                    detail ? undefined : handleSelectProduct(option)
                  }
                >
                  <ListItemIcon>
                    <Avatar
                      sx={{ borderRadius: "4px" }}
                      src={
                        !!linkImage
                          ? generateLinkImage(linkImage)
                          : ProductImage
                      }
                      alt="product image"
                    />
                  </ListItemIcon>
                  <Stack sx={{ flex: 1 }}>
                    <Stack direction="row" alignItems="flex-start" spacing={1}>
                      <Typography sx={{ fontSize: "14px", flex: 1 }}>
                        {option.ten_vt}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "primary.main",
                        }}
                      >
                        {numeralCustom(option.gia_ban_le).format()}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="flex-start" spacing={1}>
                      <Typography
                        sx={{
                          flex: 1,
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "primary.main",
                        }}
                      >
                        {option.ma_vt}
                      </Typography>
                      <Typography sx={{ fontSize: "14px", textAlign: "right" }}>
                        {option.ton00} {option.ma_dvt}
                      </Typography>
                    </Stack>
                  </Stack>
                </ListItemButton>
                <Collapse in={!!detail}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Quantity
                      minValue={0}
                      defaultValue={detail?.sl_xuat || 0}
                      warning={(detail?.sl_xuat || 0) > (detail?.ton_kho || 0)}
                      onChange={(val) => handleQuantityChange(detail, val)}
                    />
                  </Stack>
                </Collapse>
              </Box>
              <Divider />
            </React.Fragment>
          );
        }}
        renderTopList={() => {
          return (
            <>
              <Container>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    width: "100%",
                    height: "42px",
                    backgroundColor: "background.paper",
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing="5px">
                    <IconButton
                      sx={{ padding: 0 }}
                      onClick={() => setOpenFilter(true)}
                    >
                      <IoFilter size={20} />
                    </IconButton>
                    {filter?.group && (
                      <Typography sx={{ fontSize: "13px" }}>
                        {filter?.group?.label}
                      </Typography>
                    )}
                  </Stack>
                  <FormControlLabel
                    label="Chọn nhiều"
                    control={
                      <Checkbox
                        size="small"
                        checked={selectMulti}
                        onChange={(e) => {
                          dispatch(updateSelectMulti(e.target.checked));
                        }}
                      />
                    }
                    sx={{
                      flexDirection: "row-reverse",
                      marginRight: "0px",
                      "& .MuiButtonBase-root": { padding: "0px" },
                      "& .MuiTypography-root": {
                        fontSize: "13px",
                        marginRight: "5px",
                      },
                    }}
                  />
                </Stack>
              </Container>
              <Divider sx={{ margin: "0px !important" }} />
            </>
          );
        }}
        renderBottomList={
          selectMulti
            ? () => {
                return (
                  <Box
                    sx={{
                      width: "100vw",
                      height: "46px",
                      position: "fixed",
                      bottom: 0,
                      left: 0,
                      zIndex: 10,
                      backgroundColor: "background.paper",
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ height: "100%" }}
                    >
                      <Button
                        variant="contained"
                        onClick={handleReset}
                        sx={{
                          width: "50%",
                          borderRadius: "0px",
                          height: "100%",
                          color: "common.white",
                          backgroundColor: "text.secondary",
                          "&:hover": {
                            backgroundColor: "text.secondary",
                          },
                        }}
                      >
                        Chọn lại
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleCloseSearch}
                        sx={{
                          width: "50%",
                          borderRadius: "0px",
                          height: "100%",
                          color: "common.white",
                          backgroundColor: "primary.main",
                        }}
                      >
                        Xong
                      </Button>
                    </Stack>
                  </Box>
                );
              }
            : undefined
        }
        fixedScrollHeight={`calc(100vh - 50px - 1px - 42px - 1px ${
          selectMulti ? "- 46px" : ""
        })`}
      />
      <Box
        sx={{
          width: "100%",
          backgroundColor: "background.paper",
        }}
      >
        <Container sx={{ width: "100%" }}>
          <Stack
            component={MenuItem}
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ width: "100%", height: "45px", padding: 0 }}
            onClick={() => setOpenSearch(true)}
          >
            <CiSearch size={25} />
            <Typography
              sx={{ flex: 1, lineHeight: "45px", color: "text.secondary" }}
            >
              Tìm kiếm theo mã, tên hàng hóa
            </Typography>
          </Stack>
        </Container>
      </Box>
    </>
  );
}

export default SearchProduct;
