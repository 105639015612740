import { Box, Stack } from "@mui/material";
import React from "react";
import Detail from "./detail/Detail";
import { useSelector } from "react-redux";

function Details() {
  const { currentHd } = useSelector((state) => state.order);

  return (
    <Box
      sx={{
        width: "100%",
        height: "calc(100vh - 60px - 48px - 42px - 1px - 42px - 45px)",
        paddingTop: 2,
      }}
    >
      <Stack
        sx={{
          height: "calc(100vh - 60px - 48px - 42px - 1px - 16px - 42px - 45px)",
          overflow: "auto",
        }}
      >
        {(currentHd?.details || []).map((detail) => (
          <Detail key={detail._id} data={detail} />
        ))}
      </Stack>
    </Box>
  );
}

export default Details;
