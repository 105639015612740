import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useApisContext from "../../../../hooks/hookContext/useApisContext";
import { isArray } from "lodash";
import useAlertContext from "../../../../hooks/hookContext/useAlertContext";
import {
  Box,
  Button,
  Container,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { FaAngleRight } from "react-icons/fa6";
import ListDiscount from "./ListDiscount";
import { FaRegCheckCircle } from "react-icons/fa";

function Discount() {
  const showAlert = useAlertContext();
  const { asyncSearchList } = useApisContext();
  const { currentStore } = useSelector((state) => state.store);
  const { customer, currentHd } = useSelector((state) => state.order);
  const [loading, setLoading] = useState(false);
  const [discounts, setDiscounts] = useState([]);
  const [openList, setOpenList] = useState(false);

  const getDiscount = async () => {
    try {
      setLoading(true);
      const dateCompare = moment(currentHd?.ngay_ct).seconds(0).milliseconds(0);
      const resp = await asyncSearchList({
        apiCode: "dmchietkhau",
        condition: {
          page: 1,
          limit: 99999,
          q: {
            $and: [
              { status: true },
              { "exfields.loai_chiet_khau": 1 },
              {
                hieu_luc_tu: { $lte: dateCompare },
                hieu_luc_den: { $gte: dateCompare },
              },
              { $or: [{ ma_kho: currentStore?.ma_kho || "" }, { ma_kho: "" }] },
              {
                $or: [
                  {
                    ma_kh: customer?.ma_kh || "",
                    nh_kh: customer?.nh_kh || "",
                  },
                  {
                    ma_kh: customer?.ma_kh || "",
                  },
                  {
                    nh_kh: customer?.nh_kh || "",
                  },
                  {
                    ma_kh: "",
                    nh_kh: "",
                  },
                ],
              },
            ],
          },
        },
      });
      if (resp && isArray(resp)) {
        setDiscounts(resp);
      }
    } catch (error) {
      showAlert({
        type: "error",
        message: error?.message || "Something went wrong!",
      });
    } finally {
      setLoading(false);
    }
  };

  const appliedChietkhaus = useMemo(() => {
    if (!currentHd?.exfields?.dmchietkhau) {
      return [];
    }
    return currentHd.exfields.dmchietkhau || 0;
  }, [currentHd?.exfields?.dmchietkhau]);

  useEffect(() => {
    getDiscount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore?.ma_kho, customer, currentHd?.ngay_ct]);

  if (loading) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        sx={{ width: "100vw", height: "42px" }}
      >
        <Container>
          <Typography>Đang tải chương trình giảm giá...</Typography>
        </Container>
      </Stack>
    );
  }

  if (!discounts || discounts?.length <= 0) return null;

  return (
    <>
      <ListDiscount
        open={openList}
        onClose={() => setOpenList(false)}
        discounts={discounts}
      />
      <Divider />
      <Box sx={{ width: "100vw", minHeight: "42px", py: 1 }}>
        <Container sx={{ height: "100%" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ height: "100%" }}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography sx={{ fontWeight: 500 }}>
                Chương trình giảm giá
              </Typography>
              {!!discounts && discounts.length > 0 && (
                <Box
                  sx={{
                    color: "common.white",
                    backgroundColor: "error.main",
                    width: "20px",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                  }}
                >
                  {discounts.length}
                </Box>
              )}
            </Stack>
            <Button
              sx={{ textTransform: "none", fontWeight: 400 }}
              endIcon={<FaAngleRight size={16} />}
              onClick={() => setOpenList(true)}
            >
              Xem
            </Button>
          </Stack>
          <List
            sx={{
              padding: "5px 0",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            {!!appliedChietkhaus &&
              appliedChietkhaus.length > 0 &&
              appliedChietkhaus.map((ck) => {
                return (
                  <ListItem key={ck._id} sx={{ padding: "0" }}>
                    <ListItemIcon
                      sx={{ minWidth: "25px", color: "success.main" }}
                    >
                      <FaRegCheckCircle size={16} />
                    </ListItemIcon>
                    <ListItemText sx={{ margin: 0 }}>
                      {ck.ten_chietkhau}
                    </ListItemText>
                  </ListItem>
                );
              })}
          </List>
        </Container>
      </Box>
      <Divider />
    </>
  );
}

export default Discount;
