import React, { useEffect, useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

function FilterRadios({
  onChange = () => {},
  values = [{ value: "", label: "" }],
  defaultValue,
}) {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (e) => {
    const value = e.target.value;
    setValue(value);
    onChange(value);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <RadioGroup name="radio" value={value} onChange={handleChange}>
      {values.map((item) => (
        <FormControlLabel
          key={item.value}
          value={item.value}
          label={item.label}
          control={<Radio sx={{ padding: "6px" }} />}
        />
      ))}
    </RadioGroup>
  );
}

export default FilterRadios;
