import AccountPage from "../pages/account/AccountPage";
import HomePage from "../pages/home/HomePage";
import LoginPage from "../pages/login/LoginPage";
import OrderTempPage from "../pages/order-temp/OrderTempPage";
import OrderPage from "../pages/order/OrderPage";
import Ptpage from "../pages/pt/Ptpage";
import PrivateRoute from "./PrivateRoute";
import RestrictedRoute from "./RestrictedRoute";

const restrictedRoutes = [
  {
    id: "login",
    path: "/login",
    element: (
      <RestrictedRoute>
        <LoginPage />
      </RestrictedRoute>
    ),
  },
];
const privateRoutes = [
  {
    id: "dashboard",
    path: "/dashboard",
    element: (
      <PrivateRoute>
        <HomePage />
      </PrivateRoute>
    ),
  },
  {
    id: "order",
    path: "/order",
    element: (
      <PrivateRoute>
        <OrderPage />
      </PrivateRoute>
    ),
  },
  {
    id: "order-temp",
    path: "/order-temp",
    element: (
      <PrivateRoute>
        <OrderTempPage />
      </PrivateRoute>
    ),
  },
  {
    id: "pt1",
    path: "/pt1",
    element: (
      <PrivateRoute>
        <Ptpage />
      </PrivateRoute>
    ),
  },
  {
    id: "account",
    path: "/account",
    element: (
      <PrivateRoute>
        <AccountPage />
      </PrivateRoute>
    ),
  },
];

export { restrictedRoutes, privateRoutes };
