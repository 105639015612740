import React, { useState } from "react";
import {
  Box,
  Container,
  Drawer,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { FaCalendarAlt } from "react-icons/fa";
import { formatDateDisplay } from "../../utils/helpers";
import moment from "moment";
import WrapperInput from "./WrapperInput";

function DateInput({
  disabled,
  disablePast,
  disableFuture,
  disableHighlightToday,
  datePickerProps = {},
  defaultValue,
  value,
  onChange = () => {},
  label,
  id,
  errorMessage,
  labelWidth = "30%",
  labelSx = {},
  required,
}) {
  const [open, setOpen] = useState();

  const handleClose = () => {
    setOpen(false);
  };

  const handleApply = (date) => {
    onChange(moment(date).toISOString());
    handleClose();
  };

  return (
    <>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { width: "100vw", maxHeight: "80vh" } }}
      >
        <Box
          sx={{
            backgroundColor: "background.paper",
            width: "100%",
          }}
        >
          <Container>
            <DateCalendar
              disabled={disabled}
              disablePast={disablePast}
              disableFuture={disableFuture}
              disableHighlightToday={disableHighlightToday}
              value={!!value ? moment(value) : null}
              onChange={(day, section) => {
                if (section === "finish") {
                  handleApply(day);
                }
              }}
              sx={{
                margin: 0,
                width: "100%",
                "& .MuiDayCalendar-header": {
                  justifyContent: "space-between",
                },
                "& .MuiDayCalendar-weekContainer": {
                  justifyContent: "space-between",
                },
                "& .MuiYearCalendar-root": {
                  width: "100%",
                },
              }}
              slotProps={{
                calendarHeader: { sx: { padding: 0 } },
                day: {
                  sx: {
                    width: "42px",
                    height: "42px",
                    "&.Mui-selected": { color: "common.white" },
                  },
                },
                yearButton: {
                  sx: {
                    "&.Mui-selected": { color: "common.white" },
                  },
                },
              }}
              dayOfWeekFormatter={(date) => date.format("ddd")}
              {...datePickerProps}
            />
          </Container>
        </Box>
      </Drawer>
      <WrapperInput
        label={label}
        errorMessage={errorMessage}
        id={id}
        required={required}
        labelWidth={labelWidth}
        labelSx={labelSx}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{ height: "42px", padding: "0px", minHeight: "unset" }}
          component={MenuItem}
          onClick={() => setOpen(true)}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing="10px"
            sx={{ color: "text.secondary" }}
          >
            <FaCalendarAlt size={18} />
            <Typography sx={{ color: "text.primary" }}>
              {!!value
                ? formatDateDisplay(moment(value), "DD/MM/YYYY")
                : "DD/MM/YYYY"}
            </Typography>
          </Stack>
        </Stack>
      </WrapperInput>
    </>
  );
}

export default DateInput;
