import {
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import AppBar from "../appbar/AppBar";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import TextInput from "../input/TextInput";
import SelectApiInput from "../input/SelectApiInput";
import DateInput from "../input/DateInput";
import AreaInput from "../input/AreaInput";
import * as yup from "yup";
import useApisContext from "../../hooks/hookContext/useApisContext";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import useBackdropContext from "../../hooks/hookContext/useBackdropContext";

const schema = yup.object({
  ten_kh: yup.string().required("Vui lòng nhập tên khách hàng"),
  trang_thai_obj: yup
    .object()
    .typeError("Vui lòng chọn trạng thái")
    .required("Vui lòng chọn trạng thái"),
  nhom_kh: yup
    .object()
    .typeError("Vui lòng chọn nhóm khách")
    .required("Vui lòng chọn nhóm khách"),
  email: yup.string().email("Email không đúng định dạng"),
  dien_thoai: yup
    .string()
    .required("Vui lòng nhập số điện thoại")
    .matches(/^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/, {
      message: "Số điện thoại không hợp lệ",
      excludeEmptyString: true,
    }),
  id_number: yup.string().matches(/^\d+$/, {
    message: "Trường này chỉ bao gồm số",
    excludeEmptyString: true,
  }),
});

function FormCustomer({ open, onClose }) {
  const defaultData = {
    ma_kh: "",
    ten_kh: "",
    ten_kh2: "", // Tên nhà thuốc, cơ sở kinh doanh
    dien_thoai: "",
    email: "",
    ghi_chu: "",
    id_number: "", // CMND/CCCD
    ngay_sinh: "",
    ma_so_thue: "",
    status: true,
    trang_thai_obj: null,
    tinh_thanh_obj: null,
    quan_huyen_obj: null,
    xa_phuong_obj: null,
    nhom_kh: null,
    account: null,
    dia_chi: "",
    kh_yn: true,
    ncc_yn: false,
  };
  const { asyncPostData } = useApisContext();
  const [, setBackdrop] = useBackdropContext();
  const { user } = useSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: defaultData,
    resolver: yupResolver(schema),
  });

  const generateDataPost = (values) => {
    const {
      trang_thai_obj,
      tinh_thanh_obj,
      quan_huyen_obj,
      xa_phuong_obj,
      nhom_kh,
      account,
      ...fields
    } = values;
    return {
      ...fields,
      trang_thai: trang_thai_obj?.ma_trang_thai || "",
      ten_trang_thai: trang_thai_obj?.ten_trang_thai || "",
      tinh_thanh: tinh_thanh_obj?.ma_tinh_thanh || "",
      ten_tinh_thanh: tinh_thanh_obj?.ten_tinh_thanh || "",
      quan_huyen: quan_huyen_obj?.ma_quan_huyen || "",
      ten_quan_huyen: quan_huyen_obj?.ten_quan_huyen || "",
      xa_phuong: xa_phuong_obj?.ma_xa_phuong || "",
      ten_xa_phuong: xa_phuong_obj?.ten_xa_phuong || "",
      nh_kh: nhom_kh?._id || "",
      ten_nh_kh: nhom_kh?.group_name || "",
      phu_trach: user?.email,
      ten_phu_trach: user?.name || "",
      visible_to: 1,
      // email: account?.email || "",
      // name: account?.name || "",
    };
  };

  const handleSave = async (values) => {
    setBackdrop(true);
    const dataToPost = generateDataPost(values);
    await asyncPostData({ apiCode: "customer", data: dataToPost });
    reset(defaultData);
    onClose();
    setBackdrop(false);
  };

  const tinhThanh = watch("tinh_thanh_obj");
  const quanHuyen = watch("quan_huyen_obj");

  return (
    <Drawer
      open={open}
      anchor="right"
      PaperProps={{ sx: { width: "100vw", height: "100vh" } }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "background.default",
        }}
      >
        <AppBar>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ height: "100%" }}
          >
            <Stack
              sx={{ width: "100%", height: "100%" }}
              direction="row"
              alignItems="center"
              spacing="10px"
            >
              <IconButton sx={{ color: "common.white" }} onClick={onClose}>
                <MdOutlineArrowBackIosNew size={25} />
              </IconButton>
              <Typography variant="h1" sx={{ color: "common.white" }}>
                Thêm khách hàng
              </Typography>
            </Stack>
            <Button
              sx={{ color: "common.white" }}
              onClick={handleSubmit(handleSave)}
            >
              Lưu
            </Button>
          </Stack>
        </AppBar>
        <Stack
          spacing={1}
          sx={{ width: "100%", height: "calc(100vh - 60px)", overflow: "auto" }}
        >
          <Stack
            sx={{
              width: "100%",
              backgroundColor: "background.paper",
              py: 2,
              borderRadius: "4px",
            }}
            spacing={1}
          >
            <Container>
              <TextInput
                label="Mã khách"
                placeholder="Nhập hoặc tạo tự động"
                textFieldProps={{
                  ...register("ma_kh"),
                }}
              />
            </Container>
            <Container>
              <TextInput
                label="Tên khách"
                required
                placeholder="Nhập tên khách hàng"
                textFieldProps={{
                  ...register("ten_kh"),
                }}
                errorMessage={errors?.ten_kh?.message}
              />
            </Container>
            <Container>
              <TextInput
                label="Tên CSKD"
                placeholder="Nhập tên cơ sở kinh doanh"
                textFieldProps={{
                  ...register("ten_kh2"),
                }}
              />
            </Container>
            <Container>
              <TextInput
                required
                label="Điện thoại"
                placeholder="Nhập số điện thoại"
                textFieldProps={{
                  ...register("dien_thoai"),
                }}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                errorMessage={errors?.dien_thoai?.message}
              />
            </Container>
            <Container>
              <Controller
                name="nhom_kh"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <SelectApiInput
                      apiCode="dmnhkh"
                      required
                      label="Nhóm khách"
                      placeholder="Chọn nhóm khách"
                      searchPlaceholder="Tìm theo mã, tên nhóm khách"
                      searchFields={["group_name"]}
                      condition={{ isvendor: { $ne: true } }}
                      getOptionLabel={(option) => option.group_name}
                      value={value}
                      onChange={onChange}
                      errorMessage={errors?.nhom_kh?.message}
                    />
                  );
                }}
              />
            </Container>
            <Container>
              <Controller
                name="trang_thai_obj"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <SelectApiInput
                      required
                      apiCode="trangthai"
                      label="Trạng thái"
                      withIdApp={false}
                      placeholder="Chọn trạng thái khách"
                      searchPlaceholder="Tìm theo mã, tên trạng thái"
                      searchFields={["ma_trang_thai", "ten_trang_thai"]}
                      condition={{ ma_ct: "CUSTOMER", status: true }}
                      getOptionLabel={(option) => option.ten_trang_thai}
                      value={value}
                      onChange={onChange}
                      errorMessage={errors?.trang_thai_obj?.message}
                    />
                  );
                }}
              />
            </Container>
          </Stack>
          <Stack
            sx={{
              width: "100%",
              backgroundColor: "background.paper",
              py: 2,
              borderRadius: "4px",
            }}
            spacing={1}
          >
            <Container>
              <TextInput
                label="Email"
                placeholder="Nhập email khách hàng"
                textFieldProps={{
                  ...register("email"),
                }}
                errorMessage={errors?.email?.message}
              />
            </Container>
            <Container>
              <TextInput
                label="Mã số thuế"
                placeholder="Nhập mã số thuế"
                textFieldProps={{
                  ...register("ma_so_thue"),
                }}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              />
            </Container>
            <Container>
              <TextInput
                label="CMND/CCCD"
                placeholder="Nhập số CMND/CCCD"
                textFieldProps={{
                  ...register("id_number"),
                }}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                errorMessage={errors?.id_number?.message}
              />
            </Container>
            <Container>
              <Controller
                name="ngay_sinh"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <DateInput
                      label="Ngày sinh"
                      disableFuture
                      value={value}
                      onChange={onChange}
                    />
                  );
                }}
              />
            </Container>
          </Stack>
          <Stack
            sx={{
              width: "100%",
              backgroundColor: "background.paper",
              py: 2,
              borderRadius: "4px",
            }}
            spacing={1}
          >
            <Container>
              <Controller
                control={control}
                name="tinh_thanh_obj"
                render={({ field: { value, onChange } }) => {
                  return (
                    <SelectApiInput
                      apiCode="tinhthanh"
                      withIdApp={false}
                      label="Tỉnh thành"
                      placeholder="Chọn tỉnh / thành phố"
                      searchPlaceholder="Tìm theo tên tỉnh / thành phố"
                      searchFields={["ten_tinh_thanh"]}
                      getOptionLabel={(option) => option.ten_tinh_thanh}
                      value={value || { ten_tinh_thanh: "" }}
                      onChange={(newValue) => {
                        setValue("quan_huyen_obj", null);
                        setValue("xa_phuong_obj", null);
                        onChange(newValue);
                      }}
                    />
                  );
                }}
              />
            </Container>
            <Container>
              <Controller
                control={control}
                name="quan_huyen_obj"
                render={({ field: { value, onChange } }) => {
                  return (
                    <SelectApiInput
                      apiCode="quanhuyen"
                      withIdApp={false}
                      label="Quận huyện"
                      placeholder="Chọn quận huyện"
                      searchPlaceholder="Tìm theo tên quận huyện"
                      searchFields={["ten_quan_huyen"]}
                      condition={{ ma_tinh_thanh: tinhThanh?.ma_tinh_thanh }}
                      getOptionLabel={(option) => option.ten_quan_huyen}
                      value={value || { ten_quan_huyen: "" }}
                      onChange={(newValue) => {
                        setValue("xa_phuong_obj", null);
                        onChange(newValue);
                      }}
                    />
                  );
                }}
              />
            </Container>
            <Container>
              <Controller
                control={control}
                name="xa_phuong_obj"
                render={({ field: { value, onChange } }) => {
                  return (
                    <SelectApiInput
                      apiCode="xaphuong"
                      withIdApp={false}
                      label="Xã phường"
                      placeholder="Chọn xã phường"
                      searchPlaceholder="Tìm theo tên xã phường"
                      searchFields={["ten_xa_phuong"]}
                      condition={{ ma_quan_huyen: quanHuyen?.ma_quan_huyen }}
                      getOptionLabel={(option) => option.ten_xa_phuong}
                      value={value || { ten_xa_phuong: "" }}
                      onChange={onChange}
                    />
                  );
                }}
              />
            </Container>
            <Container>
              <AreaInput
                placeholder="Địa chỉ cụ thể"
                minRows={3}
                name="dia_chi"
                register={register}
              />
            </Container>
          </Stack>
          <Stack
            sx={{
              width: "100%",
              backgroundColor: "background.paper",
              py: 2,
              borderRadius: "4px",
            }}
            spacing={1}
          >
            <Container>
              <AreaInput
                placeholder="Ghi chú khách hàng"
                name="ghi_chu"
                register={register}
              />
            </Container>
          </Stack>
        </Stack>
      </Box>
    </Drawer>
  );
}

export default FormCustomer;
