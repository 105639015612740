import { FaChartPie, FaFileInvoiceDollar } from "react-icons/fa";
import { IoIosPaper } from "react-icons/io";
import { MdManageAccounts } from "react-icons/md";

const menus = [
  {
    id: "1",
    subs: [
      {
        text: "Thống kê bán hàng",
        path: "/dashboard",
        id: "thong-ke-ban-hang",
        icon: <FaChartPie size={20} />,
      },
      {
        text: "Hóa đơn tạm",
        path: "/order-temp",
        id: "hoa-don-tam",
        icon: <IoIosPaper size={20} />,
      },
      // {
      //   text: "Lịch sử đơn hàng",
      //   path: "/order-history",
      //   id: "lich-su-don-hang",
      //   icon: <FaHistory size={20} />,
      // },
    ],
  },
  // {
  //   id: 2,
  //   subs: [
  //     {
  //       text: "Xử lý đặt hàng",
  //       path: "/order-handle",
  //       id: "xu-ly-dat-hang",
  //       icon: <MdAssignmentReturned size={20} />,
  //     },
  //     {
  //       text: "Trả hàng",
  //       path: "/order-return",
  //       id: "tra-hang",
  //       icon: <PiKeyReturnBold size={20} />,
  //     },
  //   ],
  // },
  {
    id: 3,
    subs: [
      {
        text: "Lập phiếu thu",
        path: "/pt1",
        id: "phieu-thu",
        icon: <FaFileInvoiceDollar size={20} />,
      },
    ],
  },
  {
    id: 4,
    subs: [
      {
        text: "Cài đặt tài khoản",
        path: "/account",
        id: "cai-dat-tai-khoan",
        icon: <MdManageAccounts size={20} />,
      },
    ],
  },
];

export { menus };
