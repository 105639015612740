import React from "react";
import SelectApiInput from "../../../../components/input/SelectApiInput";
import { FaUserTag } from "react-icons/fa6";
import { Box, Container } from "@mui/material";
import { usePtContext } from "../../Ptpage";
import FormCustomer from "../../../../components/form/FormCustomer";

function Customer() {
  const { phieuThu, handleUpdatePhieuThu } = usePtContext();

  const handleCustomerChange = (cust) => {
    handleUpdatePhieuThu({
      ma_kh: cust?.ma_kh || "",
      ten_kh: cust?.ten_kh || "",
    });
  };

  return (
    <Box sx={{ backgroundColor: "background.paper" }}>
      <Container>
        <SelectApiInput
          placeholder="Chọn khách hàng"
          reselectToClear={false}
          searchPlaceholder="Tìm theo mã, tên khách hàng"
          startAdornment={<FaUserTag size={18} />}
          apiCode="customer"
          searchFields={["ma_kh", "ten_kh"]}
          condition={{ kh_yn: true, ncc_yn: { $ne: true } }}
          getOptionLabel={(option) =>
            option?.ten_kh ? `${option.ten_kh} (${option.ma_kh})` : ""
          }
          isOptionEqualToValue={(op, val) => op?.ma_kh === val?.ma_kh}
          value={{
            ma_kh: phieuThu?.ma_kh || "",
            ten_kh: phieuThu?.ten_kh || "",
          }}
          onChange={handleCustomerChange}
          FormAdd={FormCustomer}
        />
      </Container>
    </Box>
  );
}

export default Customer;
